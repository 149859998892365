<template lang="pug">
.clientNotifications-container
  clientNotification(
    v-for="clientNotification in clientNotifications",
    :key="clientNotification.id",
    :type="clientNotification.type",
    :title="clientNotification.title",
    @expired="removeClientNotification(clientNotification)"
  )
</template>

<script>
import ClientNotification from "~/components/ClientNotification.vue";
export default {
  components: {
    ClientNotification,
  },
  computed: {
    clientNotifications() {
      return this.$store.state.clientNotifications.queue;
    },
  },
  methods: {
    removeClientNotification(clientNotification) {
      this.$store.commit("clientNotifications/DELETE", clientNotification.id);
    },
  },
};
</script>

<style scoped>
.clientNotifications-container {
  position: absolute;
  bottom: 5px;
  right: 30px;
  transition: opacity 3s ease-in-out;
  width: 50%;
  max-width: 500px;
  opacity: 1;
  z-index: 10000;
}
</style>
