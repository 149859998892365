<template lang="pug">
//- Nested dropdown
.filter-menu(
  :class="{ show: currentFilterField, 'filter-popover': isPopover }",
  v-if="currentFilterField"
)
  .filter-card: form(
    @submit.prevent="addFilter(currentFilterField.key, computedFilterToAdd)"
  )
    //- text input
    template(v-if="currentFilterField.filter.type == 'text'")
      b-input-group
        b-form-input(
          type="text",
          size="md",
          :placeholder="currentFilterField.filter.placeholder",
          v-model="computedFilterToAdd",
          tabindex="1"
        )
        b-input-group-append
          b-button(variant="success", size="md", type="submit"): fa.d-xl-inline(
            icon="plus"
          )

    //- Select input
    template(v-if="currentFilterField.filter.type == 'enum'")
      b-input-group(v-if="!currentFilterField.filter.expand")
        b-form-select(
          :options="getCurrentEnumValues",
          v-model="computedFilterToAdd",
          tabindex="1"
        )
        b-input-group-append
          b-button(variant="success", size="md", type="submit"): fa.d-xl-inline(
            icon="plus"
          )

      template(v-else)
        //- Expanded form of select
        b-input-group
          b-form-group(:label="currentFilterField.filter.placeholder")
            b-form-radio(
              v-for="data in currentFilterField.filter.values",
              v-model="computedFilterToAdd",
              :value="data.value",
              :key="data.value",
              tabindex="1"
            ) {{ data.text }}
        b-button.w-100.px-3(variant="success", size="sm", type="submit"): fa.d-xl-inline(
          icon="plus"
        )

    //- DateTime picker
    template(v-if="currentFilterField.filter.type == 'date'")
      b-input-group.range-group(:class="{ range: usesRangeValue }")
        .flex-1: el-date-picker.w-100.no-right-border-radius(
          v-model="computedFilterToAdd.start",
          required,
          :placeholder="!usesRangeValue ? currentFilterField.filter.placeholder : 'Välj startdatum'",
          value-format="yyyy-MM-dd",
          tabindex="1"
        )
        template(v-if="usesRangeValue")
          .flex-1: el-date-picker.w-100.no-left-border-radius(
            v-model="computedFilterToAdd.end",
            required,
            placeholder="Välj slutdatum",
            value-format="yyyy-MM-dd",
            tabindex="1"
          )
        b-input-group-append(v-else)
          b-button(variant="success", size="md", type="submit"): fa.d-xl-inline(
            icon="plus"
          )

      template(v-if="currentFilterField.filter.allowRange")
        .my-3
        b-button(variant="link", @click="toggleFilterMenuRange", tabindex="1") {{ filterMenuUseRange ? "Enskilt värde" : "Intervall" }}
        b-button.float-right(
          variant="success",
          size="md",
          type="submit",
          v-if="filterMenuUseRange"
        ): fa.d-xl-inline(
          icon="plus"
        )
        .clearfix

    //- Number range
    template(v-if="currentFilterField.filter.type == 'number'")
      b-input-group.range-group(:class="{ range: usesRangeValue }")
        b-form-input.w-100.flex-1(
          v-model="computedFilterToAdd.min",
          type="number",
          min="0",
          :placeholder="!usesRangeValue ? currentFilterField.filter.placeholder : 'Min'",
          tabindex="1"
        )
        template(v-if="usesRangeValue")
          b-form-input.w-100.flex-1(
            v-model="computedFilterToAdd.max",
            type="number",
            min="0",
            placeholder="Max",
            tabindex="1"
          )
        b-input-group-append(v-else)
          b-button(variant="success", size="md", type="submit"): fa.d-xl-inline(
            icon="plus"
          )
      template(v-if="currentFilterField.filter.allowRange")
        .my-3
        b-button(variant="link", @click="toggleFilterMenuRange", tabindex="1") {{ filterMenuUseRange ? "Enskilt värde" : "Intervall" }}
        b-button.float-right(
          variant="success",
          size="md",
          type="submit",
          v-if="filterMenuUseRange"
        ): fa.d-xl-inline(
          icon="plus"
        )
        .clearfix
</template>
<script>
import { filterTypes } from "./types";
export default {
  components: {},
  props: {
    fields: {
      type: Array,
      default: () => [],
      required: false,
    },
    propAppliedFilters: {
      type: Array,
      default: () => [],
      required: true,
    },
    currentFilterField: {
      type: Object,
      required: false,
      default: () => {},
    },
    currentDefaultValue: {
      type: undefined, // accept any
      required: false,
      default: () => null,
    },
    isPopover: {
      type: Boolean,
      default: () => false,
      required: false,
    },
  },
  data() {
    return {
      filterMenuUseRange: false,
    };
  },
  computed: {
    getCurrentEnumValues() {
      let items = [];
      if (
        this.currentFilterField.filter?.type == "enum" &&
        this.currentFilterField.filter.placeholder
      ) {
        items.push({
          value: "__placeholder__",
          text: this.currentFilterField.filter.placeholder,
          disabled: true,
        });
      }
      items.push(...this.currentFilterField.filter.values);
      return items;
    },
    usesRangeValue() {
      return (
        this.currentFilterField.filter.allowRange && this.filterMenuUseRange
      );
    },
    computedFilterToAdd: {
      get: function () {
        return this.currentDefaultValue;
      },
      set: function (val) {
        this.$emit("update:currentDefaultValue", val);
      },
    },
  },
  mounted() {
    if (this.currentFilterField) {
      const filterType = filterTypes[this.currentFilterField.filter.type];
      if (filterType) {
        // Open range if it is being used
        this.filterMenuUseRange =
          this.computedFilterToAdd &&
          filterType.usesRange(
            this.currentFilterField.filter,
            this.computedFilterToAdd
          );
      }
    }
  },
  methods: {
    toggleFilterMenuRange() {
      this.filterMenuUseRange = !this.filterMenuUseRange;
    },
    addFilter(key, value) {
      this.$emit("addFilter", key, value, this.filterMenuUseRange);
    },
  },
};
</script>
<style lang="scss">
.filter-menu.show {
  width: 300px;

  > .filter-card {
    background-color: #fff;

    select > option[value="__placeholder__"] {
      display: none;
    }
  }

  &:not(.filter-popover) > .filter-card {
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 15px 10px;
    width: 100%;
  }

  .flex-1 {
    flex: 1 !important;
  }

  .el-date-editor > input {
    padding-right: 0px !important;
  }
}

@media (max-width: 400px) {
  .filter-menu.show {
    width: 225px;

    .range-group.range {
      flex-direction: column;
      gap: 5px;
      > .form-control {
        border-radius: 0.5rem !important;
      }
    }
  }
}
.no-right-border-radius input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.no-left-border-radius input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
</style>
