import sv from "../locales/sv.json";
import { getByDotNotation } from "../utils";

export default (_, inject) => {
  inject("tt", (s) => {
    const v = getByDotNotation(sv, s);
    if (!v) return s;
    else return v;
  });
};
