import merge from "deepmerge";
import { getIntervalComponents } from "../utils/interval";

const initialDeposit = {
  enabled: false,
  amount: 0,
  amountPaid: 0,
  autoSend: true,
  due: null,
  text: "Deposition som säkerhet vid uthyrning ska betalas av hyresgäst enligt belopp och sista betaldatum nedan.",
};
export const state = () => ({
  rescind: true,
  showPreview: false,
  uneditedForm: null,
  form: {
    _id: null,
    originalData: {},
    createNew: false,
    betalasForskott: true,
    startDate: null,
    endDate: null,
    vatEnabled: false,
    hyresgaster: [],
    hyresobjekt: [],
    template: null,
    avtalsbyggare: undefined,
    originalTemplate: null,
    method: "manual", //This should be BankID when added
    simpleHyra: false,
    hyra: null,
    hyror: [],
    aviseringsTyp: "email",
    uppsagningstid: "3m",
    aviseringsFrekvens: "1m",
    forskottAvisering: "2v",
    betalningsOffset: "1d",
    signed: false,
    acceptedByHyresgast: true,
    includeHyrorInThePast: true,
    tags: [],
    deposit: initialDeposit,
    ownReference: undefined,
    invitations: [],
    deleteInvites: null, // This value is to track confirmed invite deletion intents. Using the 'invitations' field could cause involuntary removals
    costCenter: null,
    termsOfPayment: null,
    _dVersion: "v1",
    propertyTax: false,
  },
  methodDictionary: {
    bankid: "BankID",
    manual: "Manuell",
    import: "Importerad",
  },
  stageDictionary: {
    start: "Inväntar hyresgäst",
    signed: "Signerat",
    acceptedByHyresgast: "Kan signeras",
    signingInProgress: "Kan signeras",
    cancelled: "Avslutat",
  },
  abbreviatedStageDictionary: {
    start: "Inväntar HG",
    signed: "Signerat",
    acceptedByHyresgast: "Kan signeras",
    signingInProgress: "Kan signeras",
    cancelled: "Avslutat",
  },

  stageVariantDictionary: {
    signed: "success",
    start: "warning",
    acceptedByHyresgast: "info",
    signingInProgress: "warning",
    cancelled: "danger",
  },
  periodOptions: [
    { text: "Dagar", value: "d" },
    { text: "Veckor", value: "v" },
    { text: "Månader", value: "m" },
    { text: "Kvartal", value: "q" },
  ],
});

export const actions = {
  async new({ commit, dispatch, getters }, reset = true) {
    const res = await this.$axios.post(
      `hyresvard/avtal`,
      getters.GET_AVTAL_FORM
    );
    dispatch(
      "clientNotifications/create",
      { title: "Nytt avtal skapades.", type: "success" },
      { root: true }
    );
    if (reset) commit("RESET_FORM");
    return res.data;
  },
  setEdit({ commit }, avtal) {
    commit("SET_FORM", avtal);
  },
  async newVersion({ dispatch, state, getters }) {
    const res = await this.$axios.post(
      `hyresvard/avtal/${state.form._id}/new-version?rescind=${state.rescind}`,
      getters.GET_AVTAL_FORM
    );
    dispatch(
      "clientNotifications/create",
      { title: "Avtalet uppdaterades.", type: "success" },
      { root: true }
    );
    return res.data;
  },
  async edit({ dispatch, state, getters }) {
    const res = await this.$axios.patch(
      `hyresvard/avtal/${state.form._id}`,
      getters.GET_AVTAL_FORM
    );
    dispatch(
      "clientNotifications/create",
      { title: "Avtalet uppdaterades.", type: "success" },
      { root: true }
    );
    return res.data;
  },
  async delete({ dispatch }, id) {
    const res = await this.$axios.delete(`hyresvard/avtal/${id}`);
    dispatch(
      "clientNotifications/create",
      { title: "Avtalet raderades.", type: "warning" },
      { root: true }
    );
    return res.data;
  },
};

export const getters = {
  GET_UPPSAGNINGSTID_NUM: (state) => {
    return getIntervalComponents(state.form.uppsagningstid)[0];
  },
  GET_UPPSAGNINGSTID_TYPE: (state) => {
    return getIntervalComponents(state.form.uppsagningstid)[1];
  },
  GET_FORSKOTTAVISERING_NUM: (state) => {
    return getIntervalComponents(state.form.forskottAvisering)[0];
  },
  GET_FORSKOTTAVISERING_TYPE: (state) => {
    return getIntervalComponents(state.form.forskottAvisering)[1];
  },
  GET_AVISERINGSFREKVENS_NUM: (state) => {
    return getIntervalComponents(state.form.aviseringsFrekvens)[0];
  },
  GET_AVISERINGSFREKVENS_TYPE: (state) => {
    return getIntervalComponents(state.form.aviseringsFrekvens)[1];
  },
  GET_BETALNINGSOFFSET_NUM: (state) => {
    return getIntervalComponents(state.form.betalningsOffset)[0];
  },
  GET_BETALNINGSOFFSET_TYPE: (state) => {
    return getIntervalComponents(state.form.betalningsOffset)[1];
  },
  GET_AVTAL_FORM: (state) => {
    return Object.assign({}, state.form, {
      versions: undefined,
      originalData: undefined,
      hyresvard: undefined,
    });
  },
};

export const mutations = {
  SET_UPPSAGNINGSTID_NUM: (state, data) => {
    let value = state.form.uppsagningstid;
    let type = getIntervalComponents(value)[1];
    state.form.uppsagningstid = data + type;
  },
  SET_UPPSAGNINGSTID_TYPE: (state, data) => {
    let value = state.form.uppsagningstid;
    let num = getIntervalComponents(value)[0];
    state.form.uppsagningstid = num + data;
  },
  SET_FORSKOTTAVISERING_NUM: (state, data) => {
    let value = state.form.forskottAvisering;
    let type = getIntervalComponents(value)[1];
    state.form.forskottAvisering = data + type;
  },
  SET_FORSKOTTAVISERING_TYPE: (state, data) => {
    let value = state.form.forskottAvisering;
    let num = getIntervalComponents(value)[0];
    state.form.forskottAvisering = num + data;
  },
  SET_AVISERINGSFREKVENS_NUM: (state, data) => {
    let value = state.form.aviseringsFrekvens;
    let type = getIntervalComponents(value)[1];
    state.form.aviseringsFrekvens = data + type;
  },
  SET_AVISERINGSFREKVENS_TYPE: (state, data) => {
    let value = state.form.aviseringsFrekvens;
    let num = getIntervalComponents(value)[0];
    state.form.aviseringsFrekvens = num + data;
  },
  SET_BETALNINGSOFFSET_NUM: (state, data) => {
    let value = state.form.betalningsOffset;
    let type = getIntervalComponents(value)[1];
    state.form.betalningsOffset = data + type;
  },
  SET_BETALNINGSOFFSET_TYPE: (state, data) => {
    let value = state.form.betalningsOffset;
    let num = getIntervalComponents(value)[0];
    state.form.betalningsOffset = num + data;
  },

  SET_FORM: (state, data) => {
    // Using same object for edit/new
    state.form = merge(
      {},
      data,
      !data["_dVersion"] ? { _dVersion: undefined } : {}
    );
    // Intialize an deposit obj if not existant
    if (state.form.deposit === undefined)
      state.form = merge({}, { ...state.form, deposit: { ...initialDeposit } });
    // We hold a copy of the data as it's needed in some places to reset to when editing
    state.uneditedForm = merge({}, data);
    state.rescind = null;
  },
  INSERT_DATA: (state, data) => {
    // Inserts a new avtal into state by deleting existing
    // avtal(s) with matching id's and adding the given one.
    let filtered = state.data.filter((avtal) => avtal._id !== data._id);
    filtered.push(data);
    state.data = filtered;
  },
  RESET_FORM: (s) => {
    const initial = state();
    s.form = initial.form;
    s.uneditedForm = {};
  },
  SET_ADDON_INPUT_VAL(state, { inputValue, inputId, addonId }) {
    let addon = state.form.template.addons.find((a) => a._id === addonId);
    addon.inputs.find((i) => i._id === inputId).value = inputValue;
  },
  REMOVE_ADDON(state, addonId) {
    let addonIndex = state.form.template.addons.findIndex(
      (a) => a._id === addonId
    );
    let newArray = [...state.form.template.addons];
    newArray.splice(addonIndex, 1); // Grr not functional :(
    state.form.template.addons = newArray;
  },
};
