import merge from "deepmerge";

export const state = () => ({
  data: [],
  new: {},
  edit: {},

  accountTypeOptions: [
    { text: "Admin", value: "admin" },
    { text: "Hyresgäst", value: "hyresgast" },
    { text: "Hyresvärd", value: "hyresvard" },
  ],
});

export const actions = {
  async fetch({ commit }) {
    const res = await this.$axios.get(`admin/anvandare`);
    commit("SET_DATA", res.data);
  },
  async new({ commit, dispatch, state }) {
    await this.$axios.post(`admin/anvandare`, {
      ...state.new,
    });
    dispatch(
      "clientNotifications/create",
      { title: "Ny Användare skapades.", type: "success" },
      { root: true }
    );
    commit("RESET_NEW");
    await dispatch("fetch");
  },
  setEdit({ commit, state }, id) {
    let anvandare = state.data.find((fstght) => fstght._id === id);
    commit("SET_EDIT", anvandare);
  },
  async edit({ dispatch, state }) {
    const res = await this.$axios.patch(`admin/anvandare/${state.edit._id}`, {
      ...state.edit,
    });
    dispatch(
      "clientNotifications/create",
      { title: "Användaren uppdaterades.", type: "success" },
      { root: true }
    );
    await dispatch("fetch");
    return res.data;
  },
  async delete({ dispatch }, id) {
    await this.$axios.delete(`admin/anvandare/${id}`);
    dispatch(
      "clientNotifications/create",
      { title: "Användaren raderades.", type: "warning" },
      { root: true }
    );
    await dispatch("fetch");
  },
};
export const mutations = {
  SET_DATA: (state, data) => {
    state.data = [...data];
  },
  SET_EDIT: (state, data) => {
    state.edit = merge({}, data);
  },
  RESET_NEW: (s) => {
    const initial = state();
    s.new = initial.new;
  },
};
