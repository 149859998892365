import Vue from "vue";
import LoadingButton from "~/components/LoadingButton";

Vue.component("LoadingButton", LoadingButton);

export default function init() {
  window.TenFastStatus = (function () {
    let initialPageLoad = false,
      blockStatusUpdate = null;

    function tenFastState() {
      return initialPageLoad;
    }

    tenFastState.Block = (promise) => {
      if (blockStatusUpdate) {
        return false;
      }

      blockStatusUpdate = promise;
      return true;
    };

    tenFastState.InitialLoad = () => {
      (blockStatusUpdate ?? Promise.resolve()).then(() => {
        initialPageLoad = true;
        blockStatusUpdate = null;
      });
    };

    return tenFastState;
  })();

  window.onNuxtReady(({ $store }) => {
    if (!$store.state.accountType) $store.dispatch("init");
    window.TenFastStatus.InitialLoad();
  });
}
