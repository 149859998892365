<template lang="pug">
div(style="max-height: 100vh")
  b-navbar(type="dark", variant="dark", :sticky="true")
    b-container
      b-navbar-brand(:to="{ name: 'hyresgast' }") TenFAST
      b-navbar-nav(v-if="this.$route.name != 'hyresgast'")
        b-nav-item(:to="{ name: 'hyresgast' }")
          fa(icon="caret-left")
          | &nbsp;
          span Tillbaka
      b-dropdown.ml-auto.text-white(right, text="Konto", variant="link")
        b-dropdown-item(:to="{ name: 'hyresgast-installningar' }") Inställningar
        b-dropdown-item(@click.prevent="logout") Logga ut
  .page-wrapper
    nuxt
  clientNotifications
</template>

<script>
import ClientNotifications from "~/components/ClientNotifications.vue";

export default {
  components: {
    ClientNotifications,
  },
  middleware: [
    ({ $auth, redirect }) => {
      if ($auth.loggedIn && $auth.user.accountType !== "hyresgast") {
        return redirect($auth.options.redirect.login);
      }
    },
    "setGtagContext",
  ],
  methods: {
    logout() {
      this.$auth.logout();
    },
  },
};
</script>

<style lang="scss">
@import "./assets/scss/custom-lux-variables.scss";
body {
  background: $gray-300;
  overflow: auto;
}
.top-bar-hg {
  // Hyresgäst navbar
  background: $gray-900;
  padding: 10px;
  color: $gray-400;
}

.text-white button {
  color: $gray-100 !important;
  font-weight: 600;
}

@media print {
  body,
  html {
    background: none !important;
    overflow: hidden;
  }
}
</style>
