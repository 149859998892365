export const state = () => ({
  categories: [],
  types: [
    {
      value: "community",
      label: "Community",
      variant: "success",
    },
    {
      value: "official",
      label: "TenFAST-mall",
      variant: "primary",
    },
    {
      value: "private",
      label: "Privat",
      variant: "warning",
    },
  ],
});

export const actions = {
  async fetchCategories({ commit }) {
    const { data } = await this.$axios.get(`hyresvard/avtalsmallar/categories`);
    commit("SET_CATEGORIES", data.categories);
    return data.categories;
  },
  async delete({ dispatch }, id) {
    await this.$axios.delete(`hyresvard/avtalsmallar/${id}`);
    dispatch(
      "clientNotifications/create",
      { title: "Avtalsmallen raderades.", type: "warning" },
      { root: true }
    );
    await dispatch("fetch");
  },
};
export const mutations = {
  SET_CATEGORIES: (state, data) => {
    state.categories = data;
  },
};
