import { render, staticRenderFns } from "./SideBar.vue?vue&type=template&id=fc7a8cda&scoped=true&lang=pug"
import script from "./SideBar.vue?vue&type=script&lang=js"
export * from "./SideBar.vue?vue&type=script&lang=js"
import style0 from "./SideBar.vue?vue&type=style&index=0&id=fc7a8cda&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc7a8cda",
  null
  
)

export default component.exports