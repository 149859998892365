import Vue from "vue";
import { Line } from "vue-chartjs";

Vue.component("MyLine", {
  extends: Line,
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.renderChart(this.data, this.options);
  },
});
