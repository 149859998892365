<template lang="pug">
b-form(@submit.prevent="onSubmit")
  b-modal#newTransaction(size="lg", :title="title", static, lazy)
    b-row
      b-col
        b-form-group(label="Belopp", label-for="new-transaction-amount")
          b-input-group
            b-form-input#new-transaction-amount(
              type="number",
              v-model="amount",
              required=""
            )
            b-button(
              @click="fillAmount",
              variant="primary",
              :disabled="amountIsMax"
            ) Sätt till resterande
          template(slot="description")
            div Kvar till helt betald:{{ " " }}
              span(
                :class="{ 'text-success': diff === 0, 'text-danger': diff > 0, 'text-warning': diff < 0 }"
              ) {{ diff | currency }}
            div Resterande: {{ restAmount | currency }}
      b-col
        b-form-group(
          label="Betalningsmetod",
          label-for="new-transaction-method"
        )
          b-form-select#new-transaction-method(
            type="select",
            v-model="method",
            required="",
            :options="methods",
            text-field="label"
          )
    b-row: b-col: b-form-group(label="Datum", label-for="new-transaction-dateTime")
      el-date-picker#new-transaction-dateTime.w-100(
        v-model="dateTime",
        required,
        value-format="yyyy-MM-dd",
        :picker-options="{ firstDayOfWeek: 1 }"
      )
    .w-100(slot="modal-footer")
      loading-button.float-right(
        variant="primary",
        :loading="loading",
        type="submit"
      ) Skapa
      b-button.float-left(@click="closeModal") Avbryt
</template>

<script>
import { mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import LoadingButton from "../LoadingButton.vue";

export default {
  components: { LoadingButton },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapFields({
      method: "transactions.new.method",
      amount: "transactions.new.amount",
      amountPaid: "transactions.new.amountPaid",
      hyra: "transactions.new.hyra",
      avtal: "transactions.new.avtal",
      type: "transactions.new.type",
      direction: "transactions.new.direction",
      dateTime: "transactions.new.dateTime",
    }),
    ...mapState({
      methods: (state) => state.transactions.methods,
    }),
    amountIsMax() {
      return this.amount == this.restAmount;
    },
    restAmount() {
      if (this.type === "deposit") {
        if (!this.avtal || !this.avtal.deposit) return;
        if (this.direction === "incoming") {
          return this.avtal.deposit.amount - this.avtal.deposit.amountPaid;
        }
        // Outgoing
        return this.avtal.deposit.amountPaid - this.avtal.deposit.amountPaidOut;
      } else {
        if (!this.hyra) return;
        return this.hyra.amount - this.hyra.amountPaid;
      }
    },
    diff() {
      return this.restAmount - this.amount;
    },
    title() {
      if (this.type === "deposit") {
        return this.direction === "incoming"
          ? "Inbetalning av deposition"
          : "Utbetalning av deposition";
      }
      return "Skapa ny Transaktion";
    },
  },
  methods: {
    closeModal() {
      this.$root.$emit("bv::hide::modal", "newTransaction");
    },
    async onSubmit() {
      try {
        this.loading = true;
        const data = await this.$store.dispatch("transactions/new");
        this.$root.$emit("bv::hide::modal", "newTransaction");
        this.$root.$emit("newTransaction", data);
      } finally {
        this.loading = false;
      }
    },
    fillAmount() {
      this.$store.commit("transactions/SET_NEW_AMOUNT", this.restAmount);
    },
  },
};
</script>
