import { render, staticRenderFns } from "./Guide.vue?vue&type=template&id=305a66f3&lang=pug"
import script from "./Guide.vue?vue&type=script&lang=js"
export * from "./Guide.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports