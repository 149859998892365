<template lang="pug">
el-input(
  :value="value",
  :placeholder="plc",
  :disabled="disabled",
  :required="required",
  @input="onInput"
)
  i(
    slot="suffix",
    :class="['el-input__icon', valid === 0 ? null : valid === 1 ? 'el-icon-error' : 'el-icon-success']",
    :type="'danger'",
    :style="{ color: valid === 1 ? '#F56C6C' : null }",
    :title="title"
  )
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    isOrgNumber: { type: Boolean, default: false },
    placeholder: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
  },
  data() {
    return {
      valid: 0, // 0 -> Not valid nor invalid, 1 -> invalid, 2 -> valid
      prevLen: 0,
    };
  },
  computed: {
    maxLength() {
      return this.isOrgNumber ? 10 : 12;
    },
    minLength() {
      return 10;
    },
    plc() {
      return (
        this.placeholder || (this.isOrgNumber ? "XXXXXX-XXXX" : "ÅÅÅÅMMDD-XXXX")
      );
    },
    title() {
      if (this.valid === 1) {
        return this.isOrgNumber
          ? "Organisationsnumret är ogiltigt."
          : "Personnumret är ogiltigt.";
      }
      return "";
    },
  },
  watch: {
    isOrgNumber() {
      // Update validity on change
      this.onInput(this.value);
    },
  },
  created() {
    this.onInput(this.value);
  },
  methods: {
    onInput(id) {
      id = "" + id; // Cast to string

      let parsedId = id.replace(/\D/g, "");

      if (
        (parsedId.length == this.maxLength ||
          parsedId.length == this.minLength) &&
        this.validateLuhn(parsedId)
      ) {
        if (!this.isOrgNumber) {
          if (this.validatePersonalNumber(parsedId)) {
            this.valid = 2;
            if (parsedId.length == 10) {
              let today = new Date();
              let idYear = parsedId.slice(0, 2);
              let year = (today.getFullYear() - 10) % 100;
              if (idYear > year) {
                id = "19" + id;
              } else {
                id = "20" + id;
              }
            }
          } else {
            this.valid = 1;
          }
        } else {
          this.valid = 2;
        }
      } else {
        this.valid = 1;
      }

      this.$emit("valid", this.valid == 2);

      if (this.valid == 2) {
        let returnId = id.replace(/\D/g, "");
        this.$emit("input", returnId.slice(0, -4) + "-" + returnId.slice(-4));
      } else {
        this.$emit("input", id);
      }
    },
    validatePersonalNumber(id) {
      if (id.length === 12) {
        let century = parseInt(id.slice(0, 2));
        if (century < 18 || century > 20) return false;
      }

      let date = id.slice(-10, -4);

      let month = parseInt(date.slice(2, 4)),
        day = parseInt(date.slice(4, 6));

      if (month > 12 || month < 1) return false;
      if (day > 31 || day < 1) return false;

      return true;
    },
    validateLuhn(id) {
      let sliced = id.slice(-10);

      if (id.length !== 12 && id.length !== 10) {
        this.valid = 1;
        return false;
      } else {
        let isValid = this.luhnCheck(sliced);
        this.valid = isValid ? 2 : 1;
        return isValid;
      }
    },
    trim(id) {
      return id.replace(/\D/g, "");
    },
    luhnCheck(ccNum) {
      let len = ccNum.length;

      let precomputed = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];

      let bit = 1,
        sum = 0,
        val;

      while (len) {
        val = parseInt(ccNum.charAt(--len), 10);
        sum += (bit ^= 1) ? precomputed[val] : val; // eslint-disable-line no-cond-assign
      }

      return sum && sum % 10 === 0;
    },
  },
};
</script>

<style></style>
