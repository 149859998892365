import merge from "deepmerge";

export const state = () => ({
  edit: {},
  stateVariantDictionary: {
    betald: "success",
    ny: "info",
    "ej-avprickad": "warning",
    "delvis-betald": "warning",
    forsenad: "danger",
    krediterad: "warning",
    makulerad: "danger",
  },
  stateDictionary: {
    betald: "Betald",
    ny: "Ny",
    "ej-avprickad": "Ej avprickad",
    "delvis-betald": "Delvis betald",
    forsenad: "Försenad",
    krediterad: "Krediterad",
    makulerad: "Makulerad",
  },
  predefinedRows: [
    {
      label: "Värme",
    },
    {
      label: "El",
    },
    {
      label: "Snöskottning",
    },
    {
      label: "Sophämtning",
    },
    {
      label: "Sotning",
    },
    {
      label: "Gas",
    },
    {
      label: "VA-avgifter",
    },
    {
      label: "Telefoni",
    },
    {
      label: "Preliminära driftskostnader",
    },
  ],
  stateList: [
    {
      value: "betald",
      label: "Betald",
      variant: "success",
    },
    {
      value: "ny",
      label: "Ny",
      variant: "info",
    },
    {
      value: "ej-avprickad",
      label: "Ej avprickad",
      variant: "warning",
    },
    {
      value: "forsenad",
      label: "Försenad",
      variant: "warning",
    },
    {
      value: "delvis-betald",
      label: "Delvis betald",
      variant: "danger",
    },
    {
      value: "krediterad",
      label: "Krediterad",
      variant: "warning",
    },
  ],
});

export const actions = {
  setEdit({ commit, state }, id) {
    let hyra = state.data.find((h) => h._id === id);
    commit("SET_EDIT", hyra);
  },
  async edit({ dispatch, state }) {
    const res = await this.$axios.patch(`hyresvard/hyror/${state.edit._id}`, {
      ...state.edit,
    });
    dispatch(
      "clientNotifications/create",
      { title: "Hyran uppdaterades.", type: "success" },
      { root: true }
    );
    return res.data;
  },
  async delete({ dispatch }, id) {
    await this.$axios.delete(`hyresvard/hyror/${id}`);
    dispatch(
      "clientNotifications/create",
      { title: "Hyran raderades.", type: "warning" },
      { root: true }
    );
  },
};
export const mutations = {
  SET_EDIT: (state, data) => {
    state.edit = merge({}, data);
  },
  RESET_NEW: (s) => {
    const initial = state();
    s.new = initial.new;
  },
};
