<template lang="pug">
div(@click="dismiss", @mouseover="mouseover", @mouseout="mouseout")
  b-alert.clientNotification(
    fade="",
    :variant="type",
    :show="show",
    dismissible
  )
    | {{ title }}
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "success",
    },
  },
  data() {
    return {
      show: true,
    };
  },
  mounted() {
    this.timeout = setTimeout(() => this.$emit("expired"), 4000);
  },
  methods: {
    dismiss(e) {
      if (e.target.type === "button") {
        clearTimeout(this.timeout);
        this.$emit("expired");
      }
    },
    mouseover() {
      clearTimeout(this.timeout);
    },
    mouseout() {
      this.timeout = setTimeout(() => this.$emit("expired"), 4000);
    },
  },
};
</script>
<style lang="scss" scoped>
.clientNotification {
  animation: scaleUp 0.2s ease-in-out;
  transform-origin: top;
}
@keyframes scaleUp {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}
</style>
