<template lang="pug">
b-button(:disabled="showLoading", @click="click", v-bind="$attrs")
  b-spinner.mr-2(v-if="showLoading", small)
  slot(v-if="!showLoading || !hideContentOnLoading")
</template>

<script>
export default {
  props: {
    onClick: {
      type: Function,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hideContentOnLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      onClickLoading: false,
    };
  },
  computed: {
    showLoading() {
      if (this.onClick === null) {
        return this.loading;
      } else {
        return this.onClickLoading;
      }
    },
  },
  methods: {
    async click(e) {
      // If used with loading prop simply emit that a click has been made
      if (!this.onClick) this.$emit("click");
      // If used with an onClick prop function, control the loading state and execute function
      else {
        this.onClickLoading = true;
        this.error = false;
        try {
          await this.onClick(e);
          this.onClickLoading = false;
        } catch (err) {
          this.error = true;
          this.onClickLoading = false;
          throw err;
        }
      }
    },
  },
};
</script>
