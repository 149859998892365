export const state = () => ({
  selectedContractor: localStorage.getItem("selectedContractor"),
  contractors: {},
  email: null,
});

export const mutations = {
  SET_SELECTED_CONTRACTOR: (state, data) => {
    localStorage.setItem("selectedContractor", data);
    state.selectedContractor = data;
  },
  SET_CONTRACTOR: (state, { email, contractors }) => {
    state.email = email;
    state.contractors = contractors;
  },
};
export const actions = {
  /**
   * Initialize state for an authenticated user
   */
  async init({ state, dispatch }) {
    try {
      if (!state.email?.length || !state.contractors?.length) {
        return;
      }

      if (this.$sentry) {
        this.$sentry.configureScope((scope) => {
          scope.setUser({
            email: state.email,
          });
          scope.setTag("accountType", "contractor");
        });
      }

      dispatch(
        "setSelectedContractor",
        state.selectedContractor ?? state.contractors[0]._id
      );
    } catch (error) {
      console.error("Couldn't log in", error);
      this.$router.replace({ name: "login" });
    }
  },
  async setSelectedContractor({ commit, state }, id) {
    const contractor = state.contractors.find((item) => item._id === id);
    if (!contractor) {
      console.error("Couldn't find specified contractor: ", id);
      return;
    }

    if (this.$sentry) {
      this.$sentry.configureScope(function (scope) {
        scope.setTag("selectedContractor", contractor._id);
      });
    }

    commit("SET_SELECTED_CONTRACTOR", contractor._id);
  },
  async logout({ commit }) {
    await this.$axios.post("/contractor/me/logout");
    commit("SET_CONTRACTOR", { email: null, contractors: null });
  },
};

export const getters = {
  currentContractor(state) {
    return state.contractors.find(
      (item) => item._id == state.selectedContractor
    );
  },
  isActive(state) {
    return !!state.email?.length;
  },
};
