export default function ({ $axios, store, query, $auth }) {
  $axios.onRequest((config) => {
    config.withCredentials = true;

    if (typeof config.params !== "object") config.params = {};

    // For automatic server-side redirection to register-page if needed
    if (query.au) {
      config.params.au = query.au;
    }

    const hv = store.state.selectedHyresvard;
    // Don't add if already specified or if hyresvardar is specified
    if (
      config.params.hyresvard == null &&
      !config.params.hyresvardar &&
      hv &&
      hv !== "null"
    ) {
      config.params.hyresvard = hv;
    } else if (config.params.hyresvard === "null") {
      delete config.params.hyresvard;
    }

    const selectedContractor = store.state.contractorUser.selectedContractor;
    if (selectedContractor && store.getters["contractorUser/isActive"]) {
      config.params.contractor = selectedContractor;
    }
  });
  $axios.onError(async (error) => {
    const code = parseInt(error.response && error.response.status);
    // We do not care about logging failed login attempts
    if (error.response && error.response.config.url.indexOf("auth") === -1) {
      console.error("API ERROR", error.response, error.response.data);
    }

    // 401 Unauthorized - We logout as this happens when the authentication is invalid
    if (code === 401) {
      if ($auth) {
        await $auth.logout();
      }
      store.commit("SET_SELECTED_HYRESVARD", null);

      // Handle error for contractors
      if (
        store.getters["contractorUser/isActive"] &&
        store.$router.history.current.name.startsWith("ansvarig")
      ) {
        window.location.reload();
      }
    }

    if (!$axios.isCancel(error)) {
      if (error.response) {
        let title = error.response.data.error || error.response.data.message;
        if (error.response.data.error && error.response.data.error.message)
          title = error.response.data.error.message;
        if (typeof title !== "string") title = "Något gick fel.";
        store.dispatch(
          "clientNotifications/create",
          { title, type: "danger" },
          {
            root: true,
          }
        );
      } else {
        store.dispatch(
          "clientNotifications/create",
          { title: "Kunde inte nå servern.", type: "danger" },
          {
            root: true,
          }
        );
        // redirect('/no-connection')
      }
      if (code === 400) {
        // redirect('/400')
      }
    }
    return Promise.reject(error);
  });
}
