export default function ({ $auth, route, query, redirect }) {
  $auth.onRedirect((to, from) => {
    // Add a redirect query for login route to handle
    // We ignore /hyresgast and /hyresvard/oversikt as these are the default
    // login routes anyways
    if (
      to === "/login" &&
      from !== "/hyresgast" &&
      from !== "/hyresvard/oversikt"
    ) {
      redirect({ query: { redirect: from }, name: "login" });
      return;
    }
    if ($auth.user) {
      if (
        $auth.user.accountType === "hyresvard" ||
        $auth.user.accountType === "admin"
      ) {
        return "/hyresvard/oversikt";
      } else {
        return "/hyresgast";
      }
    } else {
      if (query.au) {
        // No user & authentication token in query -> Redirect to registering
        redirect({
          name: "registrera-konto",
          query: { redirect: route.path, resetkey: query.au }, // For later redirecting back
        });
      }
    }
    // you can optionally change `to` by returning a new value
  });
}
