<template lang="pug">
b-form(@submit.prevent="onSubmit")
  b-modal#fastighetModal(
    @hidden="resetForm",
    size="lg",
    :title="type === 'new' ? 'Skapa ny fastighet' : 'Redigera fastighet'",
    static,
    lazy,
    no-close-on-backdrop
  )
    b-form-group(label="Fastighetsbeteckning")
      b-form-input(
        type="text",
        v-model="fastighetsbeteckning",
        required="",
        placeholder="ex VÄSTERÅS 1:117"
      )
    b-form-group(label="Postadress")
      b-form-input(
        type="text",
        v-model="postadress",
        placeholder="ex Gesällgatan 25"
      )
    b-form-group(label="Postnummer")
      b-form-input(type="text", v-model="postnummer", placeholder="ex 722 20")
    b-form-group(label="Stad")
      b-form-input(type="text", v-model="stad", placeholder="ex Västerås")
    b-form-group(
      label="Placering",
      description="Du kan dra i markören på kartan för att uppdatera koordinaterna. Koordinaterna anges i decimalform. Latitud är ett tal mellan -90 och 90. Longitud är ett tal mellan -180 och 180"
    )
      b-alert.my-1(
        v-model="showGeocodeAlert",
        :variant="geocodeVariant",
        dismissible
      ) {{ geocodeText }}
      .mt-1
        b-button-group.w-100
          b-button(
            @click="getCoordinates",
            variant="primary",
            :disabled="!stad || !postadress"
          ) Fyll koordinater utifrån adress
          b-button(@click="clearCoordinates", :disabled="!lat && !lng") Ta bort placering
        estate-map.mb-2(
          :fastigheter="mapArray",
          :autoZoom="false",
          draggableMarkers,
          @update:markerCoords="updateLocation",
          height="300px;"
        )
        b-input-group.mb-1(prepend="Latitud")
          b-form-input(
            type="number",
            v-model="lat",
            placeholder="00.00000",
            step="any",
            :max="90",
            :min="-90"
          )
        b-input-group(prepend="Longitud")
          b-form-input(
            type="number",
            v-model="lng",
            placeholder="00.00000",
            step="any",
            :max="180",
            :min="-180"
          )
    b-form-group(
      label="Beskrivning",
      description="Frivilligt. Kan användas för att visa på egen hemsida."
    )
      b-form-textarea(
        v-model="description",
        placeholder="En beskrivande text av fastigheten"
      )
    .w-100(slot="modal-footer")
      b-button.float-right(type="submit", variant="primary")
        span(v-if="type === 'new'") Skapa
        span(v-else) Spara
      b-button.float-left(@click="closeModal") Avbryt
</template>

<script>
import { mapFields } from "vuex-map-fields";
import EstateMap from "~/components/EstateMap.vue";
import geocode from "~/mixins/geocode.js";
export default {
  components: {
    EstateMap,
  },
  mixins: [geocode],
  data() {
    return {
      showGeocodeAlert: false,
      geocodeText: "",
      geocodeVariant: "",
    };
  },
  computed: {
    ...mapFields({
      postadress: `fastigheter.form.postadress`,
      postnummer: `fastigheter.form.postnummer`,
      stad: `fastigheter.form.stad`,
      fastighetsbeteckning: `fastigheter.form.fastighetsbeteckning`,
      description: `fastigheter.form.description`,
      lng: `fastigheter.form.location.lng`,
      lat: `fastigheter.form.location.lat`,
      type: `fastigheter.type`,
      fastighet: `fastigheter.form`,
    }),
    adress() {
      return `${this.postadress} ${this.stad}`;
    },
    errrorBool() {
      return !!this.geocodeError;
    },
    successBool() {
      return !!this.geocodeSuccess;
    },
    mapArray() {
      return [this.fastighet];
    },
  },
  methods: {
    closeModal() {
      this.$root.$emit("bv::hide::modal", "fastighetModal");
    },
    async onSubmit() {
      const data = await this.$store.dispatch(`fastigheter/${this.type}`);
      if (this.type === "new") {
        this.$root.$emit("newFastighet", data);
      } else {
        this.$root.$emit("editFastighet", data);
      }

      this.closeModal();
    },
    resetForm() {
      this.$store.commit("fastigheter/RESET_FORM");
      this.showGeocodeAlert = false;
    },
    async getCoordinates() {
      try {
        let { lat, lng, formattedAddress } = await this.geocode(this.adress);
        this.lat = lat;
        this.lng = lng;
        this.geocodeVariant = "success";
        this.geocodeText = `Hittade ${formattedAddress}`;
      } catch (e) {
        this.geocodeVariant = "warning";
        this.geocodeText = `Kunde inte hitta koordinater till ${this.adress}: ${e}`;
      } finally {
        this.showGeocodeAlert = true;
      }
      // this.lat = lat
      // this.lng = lng
    },
    clearCoordinates() {
      this.lat = "";
      this.lng = "";
    },
    updateLocation(location) {
      this.lat = location.lat;
      this.lng = location.lng;
    },
  },
};
</script>
