export const state = () => ({
  collapsedLg: false,
  collapsedSm: true,
  menu: [
    {
      name: "Översikt",
      routeName: "hyresvard-oversikt",
      icon: "", // custom svg used
      roles: ["admin", "hyresvard"],
    },
    {
      name: "Fastigheter",
      routeName: "hyresvard-fastigheter",
      icon: "building",
      roles: ["admin", "hyresvard"],
      access: ["read:fastigheter"],
    },
    {
      name: "Hyresobjekt",
      routeName: "hyresvard-hyresobjekt",
      icon: "box-open", // TODO: Find substitute, mayhaps
      roles: ["admin", "hyresvard"],
      access: ["read:hyresobjekt"],
    },
    {
      name: "Hyresgäster",
      icon: "users",
      roles: ["admin", "hyresvard"],
      sublinks: [
        {
          name: "Alla hyresgäster",
          routeName: "hyresvard-hyresgaster",
          icon: "users",
          access: ["read:hyresgaster"],
        },
        {
          name: "Felanmälan",
          routeName: "hyresvard-felanmalan",
          icon: "clipboard-list",
          access: ["read:complaint"],
        },
        {
          name: "Utskick",
          routeName: "hyresvard-utskick",
          icon: "envelopes-bulk",
          access: ["read:utskick"],
        },
      ],
    },
    {
      name: "Avtal",
      icon: "file-contract",
      sublinks: [
        {
          name: "Alla avtal",
          routeName: "hyresvard-avtal",
          icon: "file-contract",
          roles: ["admin", "hyresvard"],
          access: ["read:avtal"],
        },
        {
          name: "Mallar",
          routeName: "hyresvard-avtalsmallar",
          icon: "copy",
          access: ["read:mallar"],
        },
        {
          name: "Tillägg",
          routeName: "hyresvard-avtalstillagg",
          icon: "cubes",
          access: ["read:mallar"],
        },
      ],
    },
    {
      name: "Hyror & Avisering",
      icon: "file-invoice-dollar",
      roles: ["admin", "hyresvard"],
      sublinks: [
        {
          name: "Alla hyror",
          routeName: "hyresvard-hyror",
          icon: "file-invoice-dollar",
          access: ["read:hyror"],
        },
        {
          name: "Avisering",
          routeName: "hyresvard-avisering",
          icon: "paper-plane",
          badge: true,
          count: null,
          access: ["read:hyror"],
        },
        {
          name: "Bokföringsexport",
          routeName: "hyresvard-export",
          icon: "book",
          access: ["read:accounting"],
        },
      ],
    },
    {
      name: "Bank",
      icon: "building-columns",
      roles: ["admin", "hyresvard"],
      requireFeature: "bank",
      registerLink: "hyresvard-bank-registrera",
      access: ["read:accounting"],
      sublinks: [
        {
          name: "Konton",
          routeName: "hyresvard-bank-konto",
          icon: "building-columns",
        },
        {
          name: "Inbetalningar",
          routeName: "hyresvard-bank-transaktioner",
          icon: "money-check",
        },
      ],
    },
    {
      name: "Statistik",
      routeName: "hyresvard-statistik",
      icon: "chart-line",
      roles: ["admin", "hyresvard"],
      access: ["read:accounting"],
    },
    {
      name: "Kö",
      routeName: "hyresvard-ko",
      icon: "list",
      roles: ["admin", "hyresvard"],
      access: ["read:queue"],
    },
    {
      name: "Användare",
      routeName: "hyresvard-anvandare",
      icon: "user-shield",
      roles: ["admin"],
    },
  ],
});
export const actions = {
  //Update the badge numb
  updateAviBadgeCount({ commit }, count) {
    commit("setBadgeCount", { id: 8, count }); // Avisering page badge
  },
};
export const mutations = {
  toggleSm(state) {
    state.collapsedSm = !state.collapsedSm;
  },
  toggleLg(state) {
    state.collapsedLg = !state.collapsedLg;
  },
  collapseLg(state) {
    state.collapsedLg = true;
  },
  collapseSm(state) {
    state.collapsedSm = true;
  },
  openLg(state) {
    state.collapsedLg = false;
  },
  openSm(state) {
    state.collapsedSm = false;
  },
  setBadgeCount(state, { routeName, count }) {
    const index = state.menu.findIndex((i) => i.routeName === routeName);
    if (state.menu[index]) {
      state.menu[index].count = count;
    }
  },
};
