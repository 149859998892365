<template lang="pug">
.d-flex.justify-content-center(v-else)
  nav
    ul.pagination
      li.clickable(:class="['page-item', { disabled: !queriedData?.prev }]")
        a.page-link(@click="paginateApi(false)") Prev
      li.clickable(:class="['page-item', { disabled: !queriedData?.next }]")
        a.page-link(@click="paginateApi(true)") Next
</template>
<script>
export default {
  props: {
    queriedData: {
      type: null,
      required: true,
    },
    queriedCursor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    // Set pagination values
    paginateApi(next) {
      if (
        (next && !this.queriedData?.next) ||
        (!next && !this.queriedData?.prev)
      ) {
        return;
      }

      this.$emit("update:queriedCursor", {
        id: this.queriedData[next ? "next" : "prev"],
        next: next,
        sortvalue: this.queriedCursor?.sortValue,
      });
      this.$emit("change");
    },
  },
};
</script>
