import merge from "deepmerge";

export const state = () => ({
  data: {
    name: undefined,
    email: "",
    phone: "",
    username: "",
    accountType: "",
    _id: "",
    emailSettings: [],
    columnSettings: {},
    hyresvardTips: {},
    roles: [],
  },
});

export const actions = {
  async fetch({ dispatch }) {
    const res = await this.$axios.get("me");
    dispatch("setData", res.data);
    return res.data;
  },
  async edit({ dispatch, state }) {
    const res = await this.$axios.patch("me", {
      ...state.data,
    });
    dispatch(
      "clientNotifications/create",
      { title: "Din profil uppdaterades.", type: "success" },
      { root: true }
    );
    await dispatch("fetch");
    return res.data;
  },
  async changePass({ dispatch }, { oldPassword, newPassword }) {
    await this.$axios.patch("me/change-password", {
      oldPassword,
      newPassword,
    });
    dispatch(
      "clientNotifications/create",
      { title: "Ditt lösenord ändrades.", type: "success" },
      { root: true }
    );
  },
  async resetPass({ dispatch }, { password, resetKey, notification = true }) {
    const res = await this.$axios.post("me/reset-password", {
      password,
      resetKey,
    });
    if (notification) {
      dispatch(
        "clientNotifications/create",
        { title: "Ditt lösenord ändrades.", type: "success" },
        { root: true }
      );
    }
    return res.data; // user
  },
  async forgotPass({ dispatch }, email) {
    await this.$axios.post("me/request-password-reset", {
      email,
    });
    dispatch(
      "clientNotifications/create",
      {
        title: `Ett e-postmeddelande skickades till ${email}`,
        type: "success",
      },
      { root: true }
    );
  },
  setData({ commit }, data) {
    if (data.accountType) {
      commit("SET_ACCOUNT_TYPE", data.accountType, { root: true });
    }
    if (!data.hyresvardTips) {
      commit("SET_DATA", Object.assign({}, data, { hyresvardTips: {} }));
    } else {
      commit("SET_DATA", data);
    }
  },

  async updateTableColumnSettings(
    { commit, state },
    { hyresvard, tableId, columns }
  ) {
    const settings = state.data?.columnSettings ?? {};
    if (!settings[hyresvard]) settings[hyresvard] = {};
    if (!settings[hyresvard][tableId]) settings[hyresvard][tableId] = [];

    settings[hyresvard][tableId] = columns;

    const res = await this.$axios.patch("me", {
      columnSettings: settings,
    });

    commit("SET_HV_TABLE_SETTINGS", res.data?.columnSettings ?? settings);
  },
};
export const mutations = {
  SET_DATA: (state, data) => {
    state.data = merge(
      {
        columnSettings: {}, // Set columnSettings default
      },
      data
    );
  },
  SET_HV_EMAIL_SETTING: (state, { hyresvard, notificationType, bool }) => {
    // bool=true signifies that it should NOT be disabled
    let setting = state.data.emailSettings.find(
      (e) => e.hyresvard === hyresvard
    );
    if (!setting) {
      state.data.emailSettings.push({
        hyresvard,
        disabledNotifications: bool ? [] : [notificationType],
      });
      return;
    }
    let nots = setting.disabledNotifications;
    if (bool) {
      // Remove the specified notificationType
      let index = nots.indexOf(notificationType);
      if (index > -1) nots.splice(index, 1);
    } else {
      // Add the notificationType
      nots.push(notificationType);
    }
  },
  SET_HV_TABLE_SETTINGS: (state, columnSettings) => {
    state.data.columnSettings = columnSettings;
  },
  SET_HG_EMAIL_SETTING: (state, { hyresgast, notificationType, bool }) => {
    let setting = state.data.emailSettings.find(
      (e) => e.hyresgast === hyresgast
    );
    if (!setting) {
      state.data.emailSettings.push({
        hyresgast,
        disabledNotifications: bool ? [] : [notificationType],
      });
      return;
    }
    let nots = setting.disabledNotifications;
    if (bool) {
      // Remove the specified notificationType
      let index = nots.indexOf(notificationType);
      if (index > -1) nots.splice(index, 1);
    } else {
      // Add the notificationType
      nots.push(notificationType);
    }
  },
};
