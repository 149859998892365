<template lang="pug">
b-container
  b-card.mt-5.text-center
    img.mb-4(src="/logo.png", style="width: 25vw; max-width: 200px")
    div(v-if="error.statusCode === 404")
      h1.text-bold 404
      h2 Sidan kunde inte hittas
    h1(v-else) Något gick fel med sidan du sökte
    b-button(variant="primary", @click="handleClick", size="lg") Gå tillbaka
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    if (process.env.NODE_ENV !== "production") {
      console.error(
        "Navigated to error page because:",
        this.error.message,
        this.error
      );
    }
  },
  methods: {
    handleClick() {
      this.$router.push("/login");
    },
  },
};
</script>
