<template lang="pug">
div
  b-form-group(
    label="Nytt lösenord",
    :description="'Lösenordet måste innehålla minst ' + minLength + ' karaktärer'"
  )
    b-form-input(
      type="password",
      v-model="pass",
      required,
      :minlength="minLength",
      :maxlength="maxLength",
      @input="emit()"
    )
  b-form-group(v-if="!skipDoubleCheck", label="Repetera nytt lösenord")
    b-form-input(
      type="password",
      v-model="passConfirm",
      required,
      :minlength="minLength",
      :maxlength="maxLength",
      @input="emit()"
    )
    p.text-error(v-if="!passwordsMatch") Lösenorden matchar ej!
    p.text-error(v-else-if="!passwordsCorrectLength") Lösenordet måste vara mellan {{ minLength }}-256 karaktärer
    p.text-success(v-else)
      fa(icon="check")
      |
      | Lösenordet är okej!
</template>

<script>
export default {
  props: {
    skipDoubleCheck: {
      type: Boolean,
      default: false,
    },
    minLength: {
      type: Number,
      default: 8,
    },
    maxLength: {
      type: Number,
      default: 256,
    },
  },
  data() {
    return {
      pass: "",
      passConfirm: "",
    };
  },
  computed: {
    passwordsMatch() {
      if (this.skipDoubleCheck) return this.pass;

      if (this.pass == "" && this.passConfirm == "") {
        return true;
      } else {
        if (this.pass === this.passConfirm) {
          return this.pass;
        } else {
          return false;
        }
      }
    },
    passwordsCorrectLength() {
      if (
        this.passwordsMatch &&
        this.pass.length >= this.minLength &&
        this.pass.length <= 256
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    emit() {
      if (this.passwordsCorrectLength) {
        this.$emit("pass", this.passwordsMatch);
      } else {
        this.$emit("pass", false);
      }
    },
  },
};
</script>
<style scoped>
.text-error {
  color: tomato;
}
</style>
