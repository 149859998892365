<template lang="pug">
b-list-group-item.list-group-item-dark(
  :to="link.hasSublinks ? null : { name: link.routeName }",
  :disabled="link.disabled",
  :id="`main-${link.routeName}`",
  @click="hidePopover ? $emit('toggle') : link.hasSublinks && dropdownClick(link)",
  tabindex=0,
  :class="hasLinks && !link.disabled ? 'clickable' : ''"
)
  //- Special case for an icon that was missing in font awesome
  img.sidebar-icon(
    v-if="link.name === 'Översikt'",
    :src="require('~/assets/img/dashboard-icon.svg')",
    style="width: 1.25rem"
  )
  fa.sidebar-icon(v-else-if="link.icon", :icon="link.icon", fixed-width)
  |
  | {{ link.name }}
  |
  b-badge.float-right(
    v-if="typeof link.count === 'number'",
    variant="light",
    pill
  ) {{ link.count }}
  .float-right(v-if="hasLinks")
    fa(v-if="hidePopover", :icon="link.isShown ? 'caret-up' : 'caret-down'")
    fa(v-else-if="!link.isShown", icon="caret-right")
  feature-badge.float-right(
    v-if="!link.hasFeature",
    :clarify="false",
    :feature="link.requireFeature",
    style="margin-right: -15px"
  )

  b-popover(
    triggers="focus hover blur",
    :id="`popover-${link.routeName}`",
    :target="`main-${link.routeName}`",
    placement="right",
    custom-class="sidebar-hover-popover",
    boundary="viewport",
    :delay="{ show: 0, hide: 100 }",
    v-if="!hidePopover && hasLinks && !link.isActive && hasAccessibleLinks"
  )
    b-list-group
      b-list-group-item.list-group-item-dark(
        v-for="sublink in link.sublinks",
        @click="dropdownClick(sublink)",
        style="text-transform: initial",
        :key="sublink.routeName",
        :disabled="!sublink.hasFeature"
      ): span.h5 {{ sublink.name }}
</template>

<script>
import FeatureBadge from "~/components/FeatureBadge.vue";

export default {
  components: { FeatureBadge },
  props: {
    link: {
      type: Object,
      required: true,
    },
    hidePopover: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasLinks() {
      return this.link.sublinks?.length > 0;
    },
    hasAccessibleLinks() {
      return (
        this.link.hasFeature &&
        this.link.sublinks?.some((link) => link.hasFeature)
      );
    },
  },
  methods: {
    closeDropdown() {
      this.$root.$emit("bv::hide::popover", `popover-${this.link.routeName}`);
    },
    dropdownClick(link) {
      let name = link?.routeName;
      if (link.sublinks?.length > 0) {
        if (link.hasFeature) name = link.sublinks[0].routeName;
        else name = link?.registerLink;
      }

      if (name) {
        this.$router.push({
          name,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./assets/scss/custom-lux-variables.scss";

.list-group-item {
  cursor: pointer;
  border-radius: 0.5rem;
  margin: 3px 0px;
  background-color: $dark;
  color: $light;
  border-left: 3px solid;
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-color: $dark;
  &:hover {
    border-color: #333333;
    background-color: #333333 !important;
    color: $light !important;
  }
  &:focus {
    border-color: #2b2b2b;
    background-color: #2b2b2b !important;
    color: $light !important;
  }
  /*&.disabled {
      border-color: $gray-500;
      background-color: $gray-500;
    }*/
}
.list-group-item-dark.nuxt-link-active {
  background-color: #2b2b2b !important;
  border-color: white;
}
</style>
