<template lang="pug">
b-form(@submit.prevent="onSubmit")
  b-modal#newAnvandare(size="lg", title="Skapa ny användare", static, lazy)
    b-form-group(label="Kontotyp:")
      b-select#new-anvandare-accountType(
        type="text",
        v-model="accountType",
        :options="accountTypeOptions"
      )
    b-form-group(label="E-post:", label-for="new-anvandare-email")
      b-form-input#new-anvandare-email(
        type="email",
        v-model="email",
        required=""
      )
    b-form-group(label="Användarnamn:", label-for="new-anvandare-username")
      b-form-input#new-anvandare-username(type="text", v-model="username")
    confirm-password(@pass="getPassword")
    .w-100(slot="modal-footer")
      b-button.float-right(type="submit", variant="primary") Skapa
      b-button.float-left(@click="closeModal") Avbryt
</template>

<script>
import { mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import ConfirmPassword from "~/components/ConfirmPassword.vue";
export default {
  components: {
    ConfirmPassword,
  },
  computed: {
    ...mapState({
      accountTypeOptions: (s) => s.anvandare.accountTypeOptions,
    }),
    ...mapFields({
      email: "anvandare.new.email",
      username: "anvandare.new.username",
      password: "anvandare.new.password",
      accountType: "anvandare.new.accountType",
    }),
  },
  methods: {
    closeModal() {
      this.$root.$emit("bv::hide::modal", "newAnvandare");
    },
    onSubmit() {
      this.$store.dispatch("anvandare/new");
      this.$root.$emit("bv::hide::modal", "newAnvandare");
    },
    getPassword(pass) {
      this.password = pass;
    },
  },
};
</script>
