export const state = () => ({
  new: {
    amount: 0,
    method: "bankgiro",
    dateTime: null,
    hyra: null,
    direction: undefined,
    type: undefined,
  },
  methods: [
    {
      label: "Swish",
      value: "swish",
    },
    {
      label: "Bankgiro",
      value: "bankgiro",
    },
    {
      label: "Bankkonto",
      value: "bankkonto",
    },
    {
      label: "Internationell betalning",
      value: "international",
    },
  ],
});

export const actions = {
  async new({ commit, dispatch, state }) {
    const res = await this.$axios.post(
      `hyresvard/transactions`,
      Object.assign({}, state.new, {
        hyra: state.new.hyra?._id,
        avtal: state.new.avtal?._id,
      })
    );
    dispatch(
      "clientNotifications/create",
      { title: "Ny transaktion har skapats.", type: "success" },
      { root: true }
    );
    commit("RESET_NEW");
    return res.data;
  },
  /**
   * For handling rental payments
   */
  setNewHyra({ commit }, hyra) {
    if (hyra) {
      commit("SET_NEW_HYRA", hyra);
      commit("SET_NEW_AMOUNT", hyra.amount - hyra.amountPaid);
      commit("SET_NEW_TYPE", "hyra");
      commit("SET_NEW_DIRECTION", undefined);
    }
  },
  /**
   * For handling deposit transactions to an avtal
   */
  setNewAvtal({ commit }, { avtal, direction }) {
    if (avtal) {
      commit("SET_NEW_AVTAL", avtal);
      commit("SET_NEW_DIRECTION", direction);
      commit("SET_NEW_TYPE", "deposit");
      commit(
        "SET_NEW_AMOUNT",
        direction === "incoming"
          ? avtal.deposit.amount - avtal.deposit.amountPaid
          : avtal.deposit.amountPaid - avtal.deposit.amountPaidOut
      );
    }
  },
};
export const mutations = {
  SET_DATA: (state, data) => {
    state.data = data;
  },
  ADD_DATA: (state, data) => {
    state.data.push(data);
  },
  RESET_NEW: (s) => {
    const initial = state();
    s.new = initial.new;
  },
  SET_NEW_HYRA: (state, hyra) => {
    state.new.hyra = Object.assign({}, hyra);
  },
  SET_NEW_AVTAL: (state, avtal) => {
    state.new.avtal = Object.assign({}, avtal);
  },
  SET_NEW_AMOUNT: (state, amount) => {
    state.new.amount = amount;
  },
  SET_NEW_TYPE: (state, type) => {
    state.new.type = type;
  },
  SET_NEW_DIRECTION: (state, direction) => {
    state.new.direction = direction;
  },
};
