import { utc } from "moment";
export const filterTypes = {
  date: {
    default: { start: null, end: null },
    filterData(date, filterVal) {
      let startDate = utc(filterVal.start),
        endDate = utc(filterVal.end),
        normalizedDate = utc(
          date,
          (startDate.isValid() ? startDate : endDate).creationData().format
        );

      if (!normalizedDate.isValid()) {
        return;
      }

      if (startDate.isValid() && startDate.isAfter(normalizedDate))
        return false;

      if (endDate.isValid() && endDate.isBefore(normalizedDate)) return false;

      return true;
    },
    item(field, key, value, usedRange) {
      let filter = field.filter;
      if ((!value.start && !value.end) || value.end < value.start) return;
      if (
        (!filter.allowRange || (filter.allowRange && !usedRange)) &&
        value.start &&
        !value.end
      ) {
        value.end = value.start;
      }
      return { key: key, value: Object.assign({}, value) };
    },
    valueToString(obj) {
      if (obj.start == obj.end) return obj.start;

      return `${obj.start || ""},${obj.end || ""}`;
    },
    stringToValue(str) {
      let obj = { start: null, end: null };
      let values = str.split(",");
      if (values.length > 1) {
        if (values[0]?.length) {
          obj.start = values[0];
        }
        if (values[1]?.length) {
          obj.end = values[1];
        }
      } else {
        obj.start = obj.end = values[0];
      }
      return obj;
    },
    usesRange(filter, value) {
      return filter.allowRange && value && value?.start !== value?.end;
    },
  },
  text: {
    default: null,
    filterData(text, filterVal) {
      if (!filterVal) return true;
      let regExp = new RegExp(filterVal, "i");
      if (Array.isArray(text)) {
        return text.some((val) => {
          return regExp.test(val);
        });
      }
      return regExp.test(text);
    },
    item(field, key, value) {
      let filter = field.filter;
      if (
        typeof value != "string" ||
        value.length < filter.min ||
        value.length > filter.max
      ) {
        return;
      }
      return { key: key, value: value };
    },
    valueToString(value) {
      return value;
    },
    stringToValue(value) {
      return value;
    },
    usesRange() {
      return false;
    },
  },
  enum: {
    default: "__placeholder__",
    filterData(value, filterVal) {
      return Array.isArray(value)
        ? value.includes(filterVal)
        : value == filterVal;
    },
    item(field, key, value) {
      let filter = field.filter;
      let enumItem = filter.values.find((item) => item.value == value);
      if (!enumItem) {
        return;
      }
      return { key: key, value: value };
    },
    valueToString(value) {
      return value;
    },
    stringToValue(value) {
      return value;
    },
    usesRange() {
      return false;
    },
  },
  number: {
    default: { min: null, max: null },
    filterData(value, filterVal) {
      if (filterVal?.min && value < filterVal?.min) return false;

      if (filterVal?.max && value > filterVal.max) return false;

      return true;
    },
    item(field, key, value, usedRange) {
      const filter = field.filter;
      let min = parseInt(value.min),
        max = parseInt(value.max);

      if (!filter.allowRange || !usedRange) {
        if (min) {
          max = min;
        } else {
          return false;
        }
      }

      if (min && max && min > max) {
        max = min;
      }

      return { key: key, value: Object.assign({}, { min, max }) };
    },
    valueToString(obj) {
      if (obj.min == obj.max) return obj.min;

      return `${obj.min || ""},${obj.max || ""}`;
    },
    stringToValue(str) {
      let obj = { min: null, max: null };
      let values = str.split(",");
      if (values.length > 1) {
        if (values[0].length) {
          let min = parseInt(values[0]);
          obj.min = min || null;
        }

        if (values[1].length) {
          let max = parseInt(values[1]);
          obj.max = max || null;
        }
      } else {
        let val = parseInt(values[0]);
        obj.min = obj.max = val;
      }
      return obj;
    },
    usesRange(filter, value) {
      return filter.allowRange && value && value?.min !== value?.max;
    },
  },
};

export function buildFilterQuery(fields, appliedFilters) {
  const queryParam = {};

  appliedFilters.forEach((appliedfilter) => {
    const columnFilter = fields.find((item) => item.key === appliedfilter.key);
    if (!columnFilter.filter) return;

    const key =
      typeof columnFilter?.filter?.actualValue === "string"
        ? columnFilter.filter.actualValue
        : appliedfilter.key;
    const value = filterTypes[columnFilter.filter.type].valueToString(
      appliedfilter.value
    );
    queryParam[`filter[${key}]`] = value;
  });

  return queryParam;
}
