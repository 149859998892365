import merge from "deepmerge";
import {
  hyresobjektTypes,
  bostadTypes,
  parkeringTypes,
} from "../shared-utils/hyresobjekt";
export const state = () => ({
  type: "new",
  form: {
    _id: null,
    postnummer: null,
    postadress: null,
    fastighet: null,
    stad: null,
    nummer: null,
    skvNummer: null,
    kvm: null,
    typ: "bostad",
    bostadType: "rum och kök",
    parkeringType: "personbil",
    roomCount: 1,
    commonName: null,
    description: null,
  },
  types: hyresobjektTypes,
  bostadTypes,
  parkeringTypes,
  states: [
    "vacant",
    "soon-vacant",
    "soon-occupied",
    "occupied",
    "public",
    "reserved",
  ],
  stateVariantDictionary: {
    public: "success",
    vacant: "info",
    "soon-vacant": "warning",
    "soon-occupied": "warning",
    occupied: "primary",
    reserved: "warning",
  },
});

export const actions = {
  async new({ commit, dispatch, state }) {
    const res = await this.$axios.post(
      `hyresvard/hyresobjekt?populate=fastighet`,
      {
        ...state.form,
      }
    );
    dispatch(
      "clientNotifications/create",
      { title: "Nytt hyresobjekt skapades.", type: "success" },
      { root: true }
    );
    commit("RESET_FORM");
    return res.data;
  },
  setEdit({ commit }, hyresobjekt) {
    commit("SET_FORM", hyresobjekt);
    commit("SET_TYPE", "edit");
  },
  async edit({ dispatch, state }) {
    const res = await this.$axios.patch(
      `hyresvard/hyresobjekt/${state.form._id}?populate=fastighet`,
      {
        ...state.form,
      }
    );
    dispatch(
      "clientNotifications/create",
      { title: "Hyresobjektet uppdaterades.", type: "success" },
      { root: true }
    );
    return res.data;
  },
  async delete({ dispatch }, id) {
    const res = await this.$axios.delete(`hyresvard/hyresobjekt/${id}`);
    dispatch(
      "clientNotifications/create",
      { title: "Hyresobjektet raderades.", type: "warning" },
      { root: true }
    );
    return res.data;
  },
  async fillFastighet({ commit }, fastighet) {
    if (fastighet) {
      commit("FILL_FASTIGHET", fastighet);
    } else {
      commit("CLEAR_FASTIGHET");
    }
  },
};
export const mutations = {
  SET_FORM: (state, data) => {
    state.form.fastighet = null;
    const fastighet =
      data.fastighet !== null && data.fastighet._id
        ? data.fastighet._id
        : data.fastighet;
    state.form = merge(state.form, Object.assign({}, data, { fastighet }));
  },
  SET_TYPE: (state, type) => {
    state.type = type;
  },
  RESET_FORM: (s) => {
    const initial = state();
    s.form = initial.form;
  },
  FILL_FASTIGHET: (state, fastighet) => {
    // Fill the rest of the info, if it's not already filled in
    state.form.postadress = fastighet.postadress;
    state.form.postnummer = fastighet.postnummer;
    state.form.stad = fastighet.stad;
  },
  CLEAR_FASTIGHET: (state) => {
    state.form.postnummer = null;
    state.form.postadress = null;
    state.form.stad = null;
    state.form.fastighet = null;
  },
};
