import Vue from "vue";
import * as moment from "moment";
import { getFullType } from "../shared-utils/hyresobjekt";
import { convertPeriodToReadableFormat } from "../shared-utils/avtal";
import VueCurrencyFilter from "vue-currency-filter";
Vue.use(VueCurrencyFilter, {
  symbol: "kr",
  thousandsSeparator: " ",
  fractionCount: 0,
  fractionSeparator: ",",
  symbolPosition: "after",
  symbolSpacing: true,
});

const isNullOrUndefined = (v) => v == null || v == undefined;
const getDaysBetweenDates = (from, to) =>
  moment(to).diff(moment(from), "days") + 1; //Add one so that the difference in days is displayed inclusive for both from and to dates.
const toIsoDate = (val) =>
  isNullOrUndefined(val) ? null : moment(val).format("YYYY-MM-DD");
Vue.filter("toIsoDate", toIsoDate);
Vue.filter("toShortDate", (val) =>
  isNullOrUndefined(val) ? null : moment(val).format("YYMMDD")
);
Vue.filter("toIsoDateTime", (val) =>
  isNullOrUndefined(val) ? null : moment(val).format("YYYY-MM-DD HH:mm:ss")
);
Vue.filter("toIsoDateTimeNoS", (val) =>
  isNullOrUndefined(val) ? null : moment(val).format("YYYY-MM-DD HH:mm")
);
Vue.filter("toIsoTime", (val) =>
  isNullOrUndefined(val) ? null : moment(val).format("HH:mm:ss")
);
Vue.filter("fromNow", (val) =>
  isNullOrUndefined(val) ? null : moment(val).fromNow()
);

Vue.filter(
  "capitalize",
  (val) => val[0].toUpperCase() + val.slice(1).toLowerCase()
);

Vue.filter("formatBoolean", (val) => {
  if (val === true) {
    return "Ja";
  } else if (val === false) {
    return "Nej";
  } else {
    return null;
  }
});

Vue.filter("toCombinedAddress", (array) => {
  //Filter the array if there is missing a part of the address
  let a = array.filter((e) => {
    return e;
  });
  return a.join(", ");
});
Vue.filter("percentage", (number) => {
  //Filter the array if there is missing a part of the address
  let n = parseFloat(number);
  return `${n * 100} %`;
});

Vue.filter("toReadablePeriod", convertPeriodToReadableFormat);

/**
 * Get display name for either an array of hyresobjekt or a single hyresobjekt object
 */
Vue.filter("hoDisplayName", (hyresobjekt) => {
  if (!hyresobjekt) return "Hyresobjekt saknas";
  function getDNameForOne(ho) {
    let number = ho.nummer || ho.skvNummer;
    if (typeof number !== "number" && typeof number !== "string") {
      return ho.postadress;
    }
    return `${ho.postadress} - nr. ${number}`;
  }
  let hoDis;
  if (Array.isArray(hyresobjekt)) {
    if (hyresobjekt.length === 0) hoDis = "Inga hyresobjekt";
    else if (hyresobjekt.length === 1)
      hoDis = hyresobjekt[0].displayName || getDNameForOne(hyresobjekt[0]);
    else if (hyresobjekt.length > 1)
      hoDis = `${
        hyresobjekt[0].displayName || getDNameForOne(hyresobjekt[0])
      } +${hyresobjekt.length - 1}`;
    return hoDis || "Inget namn";
  } else {
    return (
      hyresobjekt.displayName || getDNameForOne(hyresobjekt) || "Inget namn"
    );
  }
});

// Similar function server-side!
const identityFn = (v) => v;
Vue.filter(
  "sumHyror",
  (hyror = [], vatOption = "exclude", newCalc = false, sumProp = "amount") => {
    const value = hyror.reduce((prev, hyra) => {
      let val = hyra[sumProp];
      let vat = hyra.vat || 0;
      val = isNaN(val) ? 0 : Number(val);
      const vatAmount = (newCalc ? identityFn : Math.round)(val * vat);
      if (vatOption === "only") {
        val = vatAmount;
      } else if (vatOption === "include") {
        val = val + vatAmount;
      }
      return val + prev;
    }, 0);

    return newCalc ? value : Math.round(value);
  }
);

Vue.filter("hgDisplayName", (hyresgast) => {
  if (hyresgast.isCompany) {
    return hyresgast.company;
  } else {
    return (
      (hyresgast.name &&
        `${hyresgast.name.first || ""} ${hyresgast.name.last || ""}`.trim()) ||
      "Inget namn"
    );
  }
});

Vue.filter("fastighetDisplayName", (fastighet) => {
  if (!fastighet) return "Ingen fastighet";
  if (!fastighet.postadress) {
    return fastighet.fastighetsbeteckning;
  } else {
    return `${fastighet.fastighetsbeteckning} (${fastighet.postadress})`;
  }
});
/**
 * Some custom hyresobjekt display name, should probably be combined with hoDisplaName
 */
Vue.filter("hoLabel", (hyresobjekt, showAddress = true) => {
  if (showAddress) {
    if (hyresobjekt.commonName)
      return `${hyresobjekt.displayName} (${hyresobjekt.commonName})`;
    else if (hyresobjekt.nummer && hyresobjekt.skvNummer)
      return `${hyresobjekt.displayName} (${hyresobjekt.skvNummer})`;
    else return hyresobjekt.displayName;
  } else {
    let str;
    let number = hyresobjekt.nummer || hyresobjekt.skvNummer;
    if (hyresobjekt.commonName && number)
      str = `${hyresobjekt.commonName} - Nr. ${number}`;
    else if (hyresobjekt.commonName) str = `${hyresobjekt.commonName}`;
    else if (hyresobjekt.nummer && hyresobjekt.skvNummer)
      str = `Nr. ${number} (${hyresobjekt.skvNummer})`;
    else if (hyresobjekt.skvNummer) str = `Nr. ${hyresobjekt.skvNummer}`;
    else str = `Saknar numrering`;
    return str;
  }
});
/**
 * Concatenated ho.typ and ho.subType
 */
Vue.filter("hoFullType", (ho) => {
  return getFullType(ho);
});

Vue.filter("getHyraPdfUrl", (hyraId) => {
  return `${process.env.API_URL}hyresvard/hyror/${hyraId}/download-pdf`;
});
Vue.filter("displayInterval", (data, showDays = true) => {
  return (
    `${toIsoDate(data.from)} till ${toIsoDate(data.to)}` +
    (showDays ? `\n(${getDaysBetweenDates(data.from, data.to)} dagar)` : "")
  );
});

Vue.filter("getDaysBetweenDates", (from, to) => getDaysBetweenDates(from, to));

// Vue.filter('kronor', val => Vue.options.filters.currency('kr', 2, { symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, decimalSeparator: ',', thousandsSeparator: '.' }))
Vue.prototype.$filters = Vue.options.filters;
