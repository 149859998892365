export const state = () => ({
  stateVariantDictionary: {
    new: "warning",
    active: "info",
    planned: "info",
    completed: "success",
  },
  priorityOptions: [
    {
      value: "low",
      text: "Låg - Ärendet kan åtgärdas när det passar.",
    },
    {
      value: "medium",
      text: "Mellan - Vore bra att ärendet åtgärdas snarast, men inte akut.",
    },
    {
      value: "high",
      text: "Hög - Ärendet är akut. Åtgärd behövs snarast.",
    },
  ],
});
