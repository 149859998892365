export const state = () => ({
  stateVariantDictionary: {
    opened: "warning",
    closed: "info",
    transferred: "info",
    printed: "success",
    cancelled: "danger",
  },
  ekopostAviseringTypes: ["physicalmail", "kivra"],
});
