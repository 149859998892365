import merge from "deepmerge";

export const state = () => ({
  type: "new",
  form: {
    name: "",
    email: "",
  },
});

export const actions = {
  async new({ dispatch, state }) {
    const res = await this.$axios.post(`hyresvard/contractors`, {
      ...state.form,
    });
    dispatch(
      "clientNotifications/create",
      { title: "Ny ansvarig skapades.", type: "success" },
      { root: true }
    );
    return res.data;
  },
  setEdit({ commit }, category) {
    commit("SET_FORM", category);
    commit("SET_TYPE", "edit");
  },
  async edit({ dispatch, state }) {
    const res = await this.$axios.patch(
      `hyresvard/contractors/${state.form._id}`,
      {
        ...state.form,
      }
    );
    dispatch(
      "clientNotifications/create",
      { title: "Ansvarig uppdaterades.", type: "success" },
      { root: true }
    );
    return res.data;
  },
  async delete({ dispatch }, id) {
    const res = await this.$axios.delete(`hyresvard/contractors/${id}`);
    dispatch(
      "clientNotifications/create",
      { title: "Ansvarig raderades.", type: "warning" },
      { root: true }
    );
    return res.data;
  },
};
export const mutations = {
  SET_FORM: (s, data) => {
    const initial = state();
    s.form = merge(initial.form, data);
  },
  SET_TYPE: (state, type) => {
    state.type = type;
  },
  RESET_FORM: (s) => {
    const initial = state();
    s.form = initial.form;
  },
};
