<template lang="pug">
aside.main-sidebar(
  :class="{ 'sidebar-collapse-lg': this.collapsedLg, 'sidebar-collapse-sm': this.collapsedSm }",
  v-click-outside="hideSm"
)
  .sidebar
    .sidebar-header
      .sidebar-brand
        nuxt-link(
          :to="{ name: 'hyresvard-oversikt' }",
          key="home",
          style="text-decoration: none",
          tabindex="-1"
        )
          h1.d-flex.align-items-center(
            style="line-height: 1; text-transform: none"
          )
            img(
              src="~/assets/img/logo-white.png",
              style="height: 31px; width: 33.7; padding-right: 5px"
            )
            span(style="margin-top: 2px") TenFAST
      fa.sidebar-sm-toggler(icon="xmark", @click="toggleSm")
    .sidebar-body
      b-list-group.list-group-root(
        v-for="(link, index) in computedLinks",
        :key="link?.routeName"
      )
        //- should probably check if event was touch instead of using windowWidth to determine
        //- if user can reliably activate hover/focus
        side-bar-item.main-sidebar-item(
          :link="link",
          :hidePopover="isMobileView",
          @toggle="link?.hasSublinks ? toggleExpand(link.routeName) : handleClick"
        )
        b-list-group(v-if="link.sublinks?.length > 0 && link.isShown")
          side-bar-item(
            v-for="sublink in link.sublinks",
            :key="sublink.routeName",
            :link="sublink",
            @click.native="handleClick"
          ) 
    page-footer
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import ClickOutside from "vue-click-outside";
import SideBarItem from "~/components/SideBarItem.vue";
import PageFooter from "~/components/PageFooter.vue";

export default {
  components: {
    SideBarItem,
    PageFooter,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      windowWidth: null,
      expandedView: null,
      hideActive: false,
      refreshKey: 0,
    };
  },
  computed: {
    ...mapState({
      fullMenu: (state) => state.sidebar.menu,
      me: (state) => state.me.data,
      hyresvardar: (state) => state.hyresvardar.data,
      collapsedLg: (state) => state.sidebar.collapsedLg,
      accountType: (state) => state.accountType,
      permissionMap: (state) => state.permissionMap,
    }),
    ...mapFields({
      collapsedSm: "sidebar.collapsedSm",
    }),
    ...mapGetters({
      currentHyresvard: "currentHyresvard",
      hasPermission: "hasPermission",
    }),
    isMobileView() {
      return this.windowWidth < 990;
    },
    menu() {
      return this.fullMenu.filter((entry) => {
        if (entry.name === "Fakturering") {
          return (
            this.currentHyresvard && this.currentHyresvard.plan !== "enterprise"
          );
        }

        return true;
      });
    },
    computedLinks() {
      this.permissionMap;
      return this.setupLinks(this.menu).filter(
        (v) => !v.hasSublinks || v.sublinks?.length > 0
      );
    },
    activeRoute() {
      const index = this.menu.findIndex(
        (link) =>
          (this.$route.name.startsWith(link.routeName) ||
            link.sublinks?.some((sublink) =>
              this.$route.name.startsWith(sublink.routeName)
            )) ??
          false
      );

      if (index >= 0) {
        const link = this.menu[index];
        return link.sublinks?.length ? index + "_data" : link.routeName;
      }

      return null;
    },
  },
  watch: {
    activeRoute(newRoute, oldRoute) {
      // Reset view new active
      if (newRoute !== oldRoute) {
        this.expandedView = null;
        this.hideActive = false;
      }
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    window.dispatchEvent(new Event("resize"));
  },
  methods: {
    ...mapMutations({
      toggleLg: "sidebar/toggleLg",
      toggleSm: "sidebar/toggleSm",
    }),
    handleClick() {
      if (this.windowWidth < 768) this.toggleSm();
    },
    hasAccess(link) {
      if (Array.isArray(link.roles) && !link.roles.includes(this.accountType))
        return false;

      if (link.access && !this.hasPermission(link.access)) return false;

      return true;
    },
    hideSm(e) {
      if (
        this.collapsedSm === false &&
        !(typeof e.target.parentElement.className.baseVal !== "undefined"
          ? e.target.parentElement.className.baseVal.includes("sidebar-toggler")
          : e.target.parentElement.className.includes("sidebar-toggler"))
      ) {
        this.collapsedSm = true;
      }
    },
    hasFeature(feature) {
      if (!feature) return true;

      if (this.currentHyresvard)
        return this.currentHyresvard.features.includes(feature);

      // checkAll hyresvardar
      return this.hyresvardar.every((hyresvard) =>
        hyresvard.features.includes(feature)
      );
    },
    setupLinks(links) {
      const data = links
        .map((currentLink, index) => {
          const link = Object.assign({}, currentLink);
          if (link.sublinks) {
            link.routeName = index + "_data";
            link.hasSublinks = true;
          }
          return link;
        })
        .filter((link) => {
          return this.hasAccess(link);
        })
        .map((link) => {
          link.isActive = link.routeName === this.activeRoute;
          link.hasFeature = this.hasFeature(link.requireFeature);
          link.isShown = !this.isMobileView
            ? link.isActive
            : (link.isActive && !this.hideActive) ^
              (this.expandedView === link.routeName);

          if (link.sublinks) {
            link.sublinks = this.setupLinks(link.sublinks);
          }

          return link;
        });

      return data;
    },
    toggleExpand(key) {
      if (key === this.activeRoute) {
        this.hideActive = !this.hideActive;
      } else {
        this.expandedView = this.expandedView !== key ? key : null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./assets/scss/custom-lux-variables.scss";

.sidebar-icon {
  margin-right: 0.3rem;
}
.main-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 230px;
  z-index: 1021;
  transition: margin 0.3s ease-in-out;
  background-color: $dark;
  color: $light;
  overflow-y: auto;
}
.sidebar-header {
  display: inline-block;
  text-align: center;
  height: 50px;
  display: flex;
  text-decoration: none !important;
  padding: 10px;
  h1 {
    margin-top: auto;
    margin-bottom: auto;
    display: inline-block;
    width: 100%;
    color: white;
  }
  .sidebar-brand {
    display: inline;
    margin: auto;
  }
}
.sidebar-body {
  margin: 0px 5px;
}
.sidebar-sm-toggler {
  float: right;
  height: 100%;
  cursor: pointer;
  display: none;
  @media screen and (max-width: 767px) {
    display: inline-block;
  }
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.sidebar-collapse-sm {
  @media screen and (max-width: 767px) {
    margin-left: -230px;
  }
}
.sidebar-collapse-lg {
  @media screen and (min-width: 768px) {
    margin-left: -230px;
  }
}
.list-group {
  border-radius: 0 !important;
}
/*Nested list-groups*/
.list-group.list-group-root {
  padding: 0;
  overflow: hidden;
}

.list-group.list-group-root .list-group {
  margin-bottom: 0;
  padding-left: 25px;
}

.list-group.list-group-root > .list-group-item:first-child {
  border-top-width: 0;
}
.list-group.list-group-root > .list-group > .list-group-item {
  //padding-left: 30px;
}
.list-group.list-group-root > .list-group > .list-group > .list-group-item {
  padding-left: 45px;
}
.main-sidebar-item {
  padding: 0.75rem;
}
</style>
