export function featureMiddleware(features) {
  const normalizedFeatureList = validateFeatures(features);

  return function ({ store, redirect }) {
    const hasAccess = featureCheck(
      store.state.hyresvardar.data,
      store.state.selectedHyresvard,
      normalizedFeatureList
    );

    if (!hasAccess) {
      redirect({ name: "hyresvard-oversikt" });
    }
  };
}

export function checkFeatureAccess(features, _vue) {
  if (
    !featureCheck(
      _vue.$store.state.hyresvardar.data,
      _vue.$store.state.selectedHyresvard,
      validateFeatures(features)
    )
  ) {
    _vue.$router.push({ name: "hyresvard-oversikt" });
  }
}

function featureCheck(hyresvardar, selectedHyresvard, features) {
  if (selectedHyresvard) {
    hyresvardar = hyresvardar.filter((hv) => hv._id === selectedHyresvard);
  }

  const hasAccess = features.every((feature) =>
    hyresvardar.every((hv) => hv.features.includes(feature))
  );

  if (!hasAccess || !hyresvardar.length) {
    return false;
  }
  return true;
}

function validateFeatures(features) {
  if (!Array.isArray(features) && typeof features !== "string") {
    throw Error(
      "checkFeatureAccess middleware expects either an array or a string"
    );
  }
  return typeof features === "string" ? [features] : features;
}
