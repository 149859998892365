export const state = () => ({
  queue: [],
  timedout: [],
  count: 0,
});

export const actions = {
  create({ commit }, data) {
    commit("ADD", { ...data });
  },
  clear({ commit }) {
    commit("CLEAR");
  },
};

export const mutations = {
  ADD(state, data) {
    state.queue.push({
      title: data.title,
      type: data.type,
      id: state.count++,
      hidden: false,
    });
  },
  HIDE(state, id) {
    let index = state.queue.findIndex((noti) => {
      return noti.id === id;
    });
    state.queue[index].hidden = true;
  },
  DELETE(state, id) {
    let index = state.queue.findIndex((noti) => {
      return noti.id === id;
    });
    state.queue.splice(index, 1);
  },
  CLEAR(state) {
    state.queue = [];
  },
};
