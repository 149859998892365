<template lang="pug">
b-form(@submit="onSubmit")
  b-modal#editHyresvard(
    size="lg",
    title="Redigera hyresvärd",
    static,
    lazy,
    v-if="edit"
  )
    b-row: b-col
      client-only
        b-form-group
          b-form-radio-group(v-model="isCompany", :options="typeOptions")
    b-row(v-if="!isCompany")
      b-col
        b-form-group(label="Förnamn:", label-for="edit-hyresvard-firstName")
          b-form-input#edit-hyresvard-firstName(
            type="text",
            v-model="firstName"
          )
      b-col
        b-form-group(label="Efternamn:", label-for="edit-hyresvard-lastName")
          b-form-input#edit-hyresvard-lastName(type="text", v-model="lastName")
    b-row(v-else): b-col
      b-form-group(label="Företagsnamn:", label-for="edit-hyresvard-company")
        b-form-input#edit-hyresvard-company(type="text", v-model="company")
    b-row: b-col
      b-form-group(
        :label="isCompany ? 'Organisationsnummer:' : 'Personnummer:'",
        label-for="edit-hyresvard-idbeteckning"
      )
        personal-number-input(v-model="idbeteckning", :isOrgNumber="isCompany")
      b-form-group(
        label="Momsregistreringsnummer",
        label-for="edit-hyresvard-momsnummer",
        v-if="isCompany"
      )
        b-form-input#edit-hyresvard-momsnummer(
          type="text",
          v-model="momsnummer"
        )
      b-form-group(label="E-post:", label-for="edit-hyresvard-email")
        b-form-input#edit-hyresvard-email(
          type="email",
          v-model="email",
          required
        )
      b-form-group(label="Postadress:", label-for="edit-hyresvard-postadress")
        b-form-input#edit-hyresvard-postadress(
          type="text",
          v-model="postadress"
        )
      b-form-group(label="Postnummer:", label-for="edit-hyresvard-postnummer")
        b-form-input#edit-hyresvard-postnummer(
          type="text",
          v-model="postnummer"
        )
      b-form-group(label="Stad:", label-for="edit-hyresvard-stad")
        b-form-input#edit-hyresvard-stad(type="text", v-model="stad")
      b-form-group(label="Telefonnummer:", label-for="edit-hyresvard-phone")
        b-form-input#edit-hyresvard-phone(type="tel", v-model="phone")
    template(v-if="isCompany")
      hr
      b-row: b-col
        h4
          span(v-if="firmatecknare.length < 1") Firmatecknare
          span(v-else) Firmatecknare ({{ firmatecknare.length }})
          | &nbsp;
          b-button(@click="addFt", variant="primary") Lägg till en firmatecknare
        p.mt-0: small Firmatecknare behövs för att kunna signera avtal via BankID.
        p.text-center(v-if="firmatecknare.length < 1"): em Det finns ännu inga firmatecknare till {{ company }}
        b-form-group(
          :label="'Firmatecknare ' + (index + 1) + ':'",
          v-for="(ft, index) in firmatecknare",
          :key="index"
        )
          .input-group.mb-3
            .form-control-plaintext
              personal-number-input(
                v-model="ft.idbeteckning",
                placeholder="YYYYMMDD-XXXX"
              )
            .input-group-append
              b-button.pull-right(@click="DeleteFt(index)") Ta bort
    .w-100(slot="modal-footer")
      LoadingButton.float-right(
        type="submit",
        variant="primary",
        :loading="saveLoading"
      ) Spara
      b-button.float-left(@click="closeModal") Avbryt
</template>

<script>
import { mapFields, mapMultiRowFields } from "vuex-map-fields";
import PersonalNumberInput from "~/components/PersonalNumberInput.vue";
import LoadingButton from "../LoadingButton.vue";
export default {
  components: {
    PersonalNumberInput,
    LoadingButton,
  },
  data() {
    return {
      swishl: false,
      saveLoading: false,
    };
  },
  computed: {
    ...mapFields({
      edit: "hyresvardar.edit",
      firstName: "hyresvardar.edit.name.first",
      lastName: "hyresvardar.edit.name.last",
      company: "hyresvardar.edit.company",
      idbeteckning: "hyresvardar.edit.idbeteckning",
      phone: "hyresvardar.edit.phone",
      postadress: "hyresvardar.edit.postadress",
      postnummer: "hyresvardar.edit.postnummer",
      stad: "hyresvardar.edit.stad",
      momsnummer: "hyresvardar.edit.momsnummer",
      email: "hyresvardar.edit.email",
      isCompany: "hyresvardar.edit.isCompany",
      typeOptions: "hyresvardar.typeOptions",
    }),
    ...mapMultiRowFields({
      firmatecknare: "hyresvardar.edit.firmatecknare",
    }),
  },
  methods: {
    closeModal() {
      this.$root.$emit("bv::hide::modal", "editHyresvard");
      this.$store.commit("hyresvardar/SET_EDIT", null);
    },
    async onSubmit(evt) {
      if (this.saveLoading) return;
      evt.preventDefault();
      try {
        this.saveLoading = true;
        await this.$store.dispatch("hyresvardar/edit");
        this.saveLoading = false;
        await this.$root.$emit("bv::hide::modal", "editHyresvard");
        this.$store.commit("hyresvardar/SET_EDIT", null);
      } finally {
        this.saveLoading = false;
      }
    },
    addFt() {
      this.$store.commit("hyresvardar/ADD_EDIT_FT");
    },
    DeleteFt(index) {
      this.$store.commit("hyresvardar/DELETE_EDIT_FT", index);
    },
  },
};
</script>
