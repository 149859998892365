<template lang="pug">
div(style="max-height: 100vh")
  b-navbar(type="dark", variant="dark", :sticky="true", toggleable="sm")
    b-container
      b-navbar-brand(:to="{ name: 'ansvarig' }") TenFAST
      b-navbar-nav.d-none.d-sm-block(v-if="this.$route.name != 'ansvarig'")
        b-nav-item(@click="routeChange")
          fa(icon="caret-left")
          | &nbsp;
          span Tillbaka

      b-navbar-toggle.custom-navbar-toggle(target="nav_collapse")
        fa(icon="bars")
      b-collapse#nav_collapse(is-nav): b-navbar-nav.ml-auto
        b-form-select(
          :options="contractorOptions",
          @change="selectContractor",
          :value="selectedContractor._id"
        )
        b-dropdown.text-white(right, :text="`Konto`", variant="link")
          b-dropdown-item(@click.prevent="logout") Logga ut
  .page-wrapper
    nuxt
  clientNotifications
</template>

<script>
import ClientNotifications from "~/components/ClientNotifications.vue";

export default {
  components: {
    ClientNotifications,
  },
  auth: false,
  middleware: ["contractorMiddleware"],
  computed: {
    contractorOptions() {
      return this.$store.state.contractorUser.contractors
        .filter((contractor) => typeof contractor.hyresvard !== "string")
        .map((contractor) => {
          return {
            value: contractor._id,
            text: contractor.hyresvard.displayName,
          };
        });
    },
    selectedContractor() {
      return this.$store.getters["contractorUser/currentContractor"];
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch("contractorUser/logout");
      window.location.reload();
    },
    selectContractor(id) {
      if (id !== this.selectedContractor._id) {
        this.$store.dispatch("contractorUser/setSelectedContractor", id);
        if (this.$route.name === "ansvarig") {
          this.$nuxt.refresh();
        } else {
          this.$router.push({ name: "ansvarig" });
        }
      }
    },
    routeChange() {
      if (window.history.length === 1) {
        this.$router.push({ name: "ansvarig" });
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss">
@import "./assets/scss/custom-lux-variables.scss";
body {
  background: $gray-300;
  overflow: auto;
}
.top-bar-hg {
  // Hyresgäst navbar
  background: $gray-900;
  padding: 10px;
  color: $gray-400;
}

.text-white button {
  color: $gray-100 !important;
  font-weight: 600;
}

span.contractorName {
  color: $gray-100 !important;
  text-transform: initial;
  font-size: 0.75em;
}

@media print {
  body,
  html {
    background: none !important;
    overflow: hidden;
  }
}
</style>
