<template lang="pug">
b-form(@submit="onSubmit")
  b-modal#newHyresvard(size="lg", title="Skapa ny hyresvärd", static, lazy)
    client-only
      b-row: b-col: b-form-group
        b-form-radio-group(
          v-model="isCompany",
          :options="typeOptions",
          name="radioInline"
        )
    b-row(v-if="!isCompany")
      b-col
        b-form-group(label="Förnamn:", label-for="new-hyresvard-firstName")
          b-form-input#new-hyresvard-firstName(
            type="text",
            v-model="firstName",
            required
          )
      b-col
        b-form-group(label="Efternamn:", label-for="new-hyresvard-lastName")
          b-form-input#new-hyresvard-lastName(
            type="text",
            v-model="lastName",
            required
          )
    b-row: b-col 
      b-form-group(
        label="Företagsnamn:",
        label-for="new-hyresvard-company",
        v-if="isCompany"
      )
        b-form-input#new-hyresvard-company(
          type="text",
          v-model="company",
          required
        )
      b-form-group(
        :label="isCompany ? 'Organisationsnummer:' : 'Personnummer:'",
        label-for="new-hyresvard-idbeteckning"
      )
        personal-number-input(v-model="idbeteckning", :isOrgNumber="isCompany")
      b-form-group(
        label="Momsregistreringsnummer",
        label-for="new-hyresvard-momsnummer",
        v-if="isCompany"
      )
        b-form-input#new-hyresvard-momsnummer(
          type="text",
          v-model="momsnummer"
        )
      b-form-group(label="E-post:", label-for="new-hyresvard-email")
        b-form-input#new-hyresvard-email(
          type="email",
          v-model="email",
          required
        )
      b-form-group(label="Postadress:", label-for="new-hyresvard-postadress")
        b-form-input#new-hyresvard-postadress(
          type="text",
          v-model="postadress"
        )
      b-form-group(label="Postnummer:", label-for="new-hyresvard-postnummer")
        b-form-input#new-hyresvard-postnummer(
          type="text",
          v-model="postnummer"
        )
      b-form-group(label="Stad:", label-for="new-hyresvard-stad")
        b-form-input#new-hyresvard-stad(type="text", v-model="stad")
      b-form-group(label="Telefonnummer:", label-for="new-hyresvard-phone")
        b-form-input#new-hyresvard-phone(type="tel", v-model="phone")
    template(v-if="isCompany")
      hr
      b-row: b-col
        h4
          span(v-if="firmatecknare.length < 1") Firmatecknare
          span(v-else) Firmatecknare ({{ firmatecknare.length }})
          | &nbsp;
          b-button(@click="addFt", variant="primary") Lägg till en firmatecknare

        b-form-group(
          :label="'Firmatecknare ' + (index + 1) + ':'",
          v-for="(ft, index) in firmatecknare",
          :key="index"
        )
          .input-group.mb-3
            .form-control-plaintext
              personal-number-input(
                v-model="ft.idbeteckning",
                placeholder="YYYYMMDD-XXXX"
              )
            .input-group-append
              b-button.pull-right(@click="DeleteFt(index)") Ta bort
    .w-100(slot="modal-footer")
      b-button.float-right(type="submit", variant="primary") Skapa
      b-button.float-left(@click="closeModal") Avbryt
</template>

<script>
import { mapFields, mapMultiRowFields } from "vuex-map-fields";
import PersonalNumberInput from "~/components/PersonalNumberInput.vue";

export default {
  components: {
    PersonalNumberInput,
  },
  computed: {
    ...mapFields({
      firstName: "hyresvardar.new.name.first",
      lastName: "hyresvardar.new.name.last",
      company: "hyresvardar.new.company",
      idbeteckning: "hyresvardar.new.idbeteckning",
      phone: "hyresvardar.new.phone",
      postadress: "hyresvardar.new.postadress",
      postnummer: "hyresvardar.new.postnummer",
      stad: "hyresvardar.new.stad",
      momsnummer: "hyresvardar.new.momsnummer",
      email: "hyresvardar.new.email",
      isCompany: "hyresvardar.new.isCompany",
      typeOptions: "hyresvardar.typeOptions",
    }),
    ...mapMultiRowFields({
      firmatecknare: "hyresvardar.new.firmatecknare",
    }),
  },
  methods: {
    closeModal() {
      this.$root.$emit("bv::hide::modal", "newHyresvard");
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.$store.dispatch("hyresvardar/new");
      this.$root.$emit("bv::hide::modal", "newHyresvard");
    },
    addFt() {
      this.$store.commit("hyresvardar/ADD_NEW_FT");
    },
    DeleteFt(index) {
      this.$store.commit("hyresvardar/DELETE_NEW_FT", index);
    },
  },
};
</script>
