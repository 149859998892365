export const strict = false; // Vue-tables-2 filter bug

import { getField, updateField } from "vuex-map-fields";
import { accessCheck } from "../middleware/accessMiddleware";

export const state = () => ({
  accountType: null,
  selectedHyresvard: localStorage.getItem("selectedHyresvard"),
  popSelectedHyresvard: {},
  permissionMap: {},
  loading: true,
});

export const mutations = {
  SET_ACCOUNT_TYPE: (state, accType) => {
    state.accountType = accType;
  },
  SET_SELECTED_HYRESVARD: (state, data) => {
    localStorage.setItem("selectedHyresvard", data);
    state.selectedHyresvard = data;
  },
  SET_POP_SELECTED_HYRESVARD: (state, data) => {
    state.popSelectedHyresvard = data;
  },
  SET_LOADING: (state, data) => {
    state.loading = data;
  },
  SET_PERMISSIONS: (state, data) => {
    state.permissionMap = data;
  },
  updateField,
};
export const actions = {
  /**
   * Initialize state for an authenticated user
   */
  async init({ dispatch, commit }) {
    try {
      const { loggedIn, user } = this.$auth;
      if (!loggedIn) {
        return;
      }
      commit("SET_LOADING", true);

      dispatch("me/setData", user);

      // Connect sentry with the user
      this.$sentry.configureScope((scope) => {
        scope.setUser({
          id: user._id,
          email: user.email,
        });
        scope.setTag("accountType", user.accountType);
      });

      if (user.accountType === "hyresvard" || user.accountType === "admin") {
        await dispatch("initHyresvardFetch");
      }
    } catch (error) {
      console.error("Couldn't log in", error);
      this.$router.replace({ name: "login" });
    } finally {
      commit("SET_LOADING", false);
    }
  },
  // Initial fetch for Hyresvardar
  async initHyresvardFetch({ dispatch, state }) {
    // Do the actual fetching
    const [hyresvardar] = await Promise.all([
      dispatch("hyresvardar/fetch"),
      dispatch("avtalTemplates/fetchCategories"),
    ]);

    // Figure out what hyresvard should be selected

    let hvToSelect;
    const { selectedHyresvard } = state;
    if (hyresvardar.length === 0) {
      // User doesn't have access to any hyresvardar
      this.$router.push({ name: "hyresvard-intro" });
    } else if (!selectedHyresvard) {
      // User has access to at least one, but haven't selected any
      if (hyresvardar.length === 1) {
        hvToSelect = hyresvardar[0]._id; // If user only has one, select it
      } else {
        hvToSelect = null; // If user has more than one, "View all"
      }
    } else {
      // User has a hyresvard selected
      let hvExists = hyresvardar.some((d) => d._id == selectedHyresvard);
      if (hvExists) {
        hvToSelect = selectedHyresvard; // If the hyresvard exists, select it
      } else {
        // If the selected hyresvard can't be found select first or all hyresvardar
        if (hyresvardar.length === 1) {
          hvToSelect = hyresvardar[0]._id; // If user only has one, select it
        } else {
          hvToSelect = null; // If user has more than one, "View all"
        }
      }
    }

    await dispatch("setSelectedHyresvard", {
      hvToSelect,
      redirectFix: false,
    });
  },
  // Sets the selected hyresvard (dropdown in navbar)
  async setSelectedHyresvard(
    { commit, state, dispatch },
    { hvToSelect, redirectFix }
  ) {
    const popHv =
      state.hyresvardar.data.find((h) => h._id === hvToSelect) || {};

    if (this.$sentry) {
      this.$sentry.configureScope(function (scope) {
        scope.setTag("selectedHyresvard", hvToSelect);
      });
    }

    commit("SET_SELECTED_HYRESVARD", hvToSelect);
    commit("SET_POP_SELECTED_HYRESVARD", popHv);
    commit("hyresvardar/SET_EDIT", null);

    await dispatch("updatePermissions", {
      hvToSelect,
    });

    // Only run when changing with dropdown (not on initial page load)
    if (redirectFix) {
      // If on, for example, a specific resource there is a chance that the hyresvard we are switching to does not have access to it
      // So we specify what routes are safe to stay on, and on everything else we redirect to oversikt
      const safeRoutes = [
        "hyresvard-fastigheter",
        "hyresvard-hyresobjekt",
        "hyresvard-hyresgaster",
        "hyresvard-avtal",
        "hyresvard-avtalsmallar",
        "hyresvard-avtalstillagg",
        "hyresvard-hyror",
        "hyresvard-avisering",
        "hyresvard-export",
        "hyresvard-statistik",
        "hyresvard-fakturering",
        "hyresvard-oversikt",
        "hyresvard-utskick",
        "hyresvard-felanmalan",
        "hyresvard-bank-registrera",
        "hyresvard-intro",
      ];
      const routesNotSafeForAllHvs = ["hyresvard-fakturering"];
      const route = this.$router.history.current;
      let safe = true;
      if (!safeRoutes.includes(route.name)) {
        safe = false;
      }
      if (!hvToSelect && routesNotSafeForAllHvs.includes(route.name)) {
        safe = false;
      }
      if (safe) {
        this.$router.push({ name: "hyresvard-go-back" }); // this will route to a page which will just go back to previous (to active asyncData, which seems to not always fetch when logging in for first time)
      } else {
        this.$router.push({ name: "hyresvard-oversikt" });
      }
    }
  },
  async updatePermissions({ commit }) {
    // if null, then we want to get a inclusive permissionMap, otherwise query users permission for specific hv
    let permissions = {};
    const res = await this.$axios.get(`/hyresvard/hyresvardar/permissions`);
    permissions = res.data;

    commit("SET_PERMISSIONS", permissions);
  },
};
export const getters = {
  getField, // Default for vuex-map-fields
  currentHyresvard(state) {
    return state.hyresvardar.data.find(
      (hv) => hv._id === state.selectedHyresvard
    );
  },
  hasPermission(state) {
    return (checks) => accessCheck(state.permissionMap, checks);
  },
};
