<template lang="pug">
div
  GmapMap(
    ref="map",
    :center="{ lat: 59.6099, lng: 16.544809 }",
    :zoom="zoom",
    :style="`width: 100%; height: ${this.height}`",
    :options="{ styles: mapStyles }"
  )
    gmap-cluster(v-if="google", zoomOnClick, :maxZoom="14")
      GmapMarker(
        v-for="(marker, index) in markers",
        :key="index",
        :position="marker.fastighet.location",
        :clickable="true",
        :draggable="draggableMarkers",
        :title="draggableMarkers ? 'Dra och släpp mig' : null",
        @dragend="updateMarkerCoords",
        :animation="google.maps.Animation.DROP",
        @click="openMarker(index)",
        :icon="{ url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg), scaledSize: new google.maps.Size(40, 40) }",
        labelContent="asd"
      )
        gmap-info-window(:opened="marker.opened"): .info-window
          .h4 {{ marker.fastighet.postadress }}
          div(v-if="marker.imageUrl"): b-img-lazy(
            :src="marker.imageUrl",
            fluid-grow
          )
          b-button.my-2.w-100(
            variant="primary",
            :to="{ name: 'hyresvard-fastigheter-id', params: { id: marker.fastighet._id } }"
          ) Till fastigheten
          .text-center
            span.text-bold Hyresobjekt
            hr.my-1
            div(v-if="loadingHyresobjekt")
              b-spinner.mt-1
            div(v-else-if="marker.fastighet.hyresobjekt.length === 0")
              em Fastigheten har inga hyresobjekt kopplade.
            div(v-else, v-for="ho in marker.fastighet.hyresobjekt")
              nuxt-link(
                :to="{ name: 'hyresvard-hyresobjekt-id', params: { id: ho._id } }"
              )
                span {{ ho | hoLabel(false) }}
  b-alert.mt-2(
    variant="info",
    :show="showUnplacedAlert && unplacedFastigheter.length > 0"
  )
    span Det finns {{ unplacedFastigheter.length }} {{ unplacedFastigheter.length === 1 ? "oplacerad fastighet" : "oplacerade fastigheter" }}.
      b-button(inline, @click="geocodeUnplacedFastigheter", variant="link") Placera automatiskt
</template>

<script>
import geocode from "~/mixins/geocode.js";
import { gmapApi } from "vue2-google-maps";

export default {
  mixins: [geocode],
  props: {
    fastigheter: {
      type: Array,
      default() {
        return [];
      },
    },
    autoZoom: {
      type: Boolean,
      default: true,
    },
    draggableMarkers: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "500px",
    },
    showUnplacedAlert: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openMarkerIndex: -1,
      placingInProgress: false,
      hyresobjekt: [],
      loadingHyresobjekt: false,
      zoom: 7,
      errors: [],
      results: [],
      available:
        '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-prefix="fas" data-icon="building" class="svg-inline--fa fa-building fa-w-14" role="img" viewBox="0 0 448 512"><path fill="#4CAF50" d="M436 480h-20V24c0-13.255-10.745-24-24-24H56C42.745 0 32 10.745 32 24v456H12c-6.627 0-12 5.373-12 12v20h448v-20c0-6.627-5.373-12-12-12zM128 76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76zm0 96c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40zm52 148h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12zm76 160h-64v-84c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v84zm64-172c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40z"/></svg>',
      occupied:
        '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-prefix="fas" data-icon="building" class="svg-inline--fa fa-building fa-w-14" role="img" viewBox="0 0 448 512"><path fill="#F44336" d="M436 480h-20V24c0-13.255-10.745-24-24-24H56C42.745 0 32 10.745 32 24v456H12c-6.627 0-12 5.373-12 12v20h448v-20c0-6.627-5.373-12-12-12zM128 76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76zm0 96c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40zm52 148h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12zm76 160h-64v-84c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v84zm64-172c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40z"/></svg>',
      // svg: '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-prefix="fas" data-icon="building" class="svg-inline--fa fa-building fa-w-14" role="img" viewBox="0 0 448 512"><path fill="#212121" d="M436 480h-20V24c0-13.255-10.745-24-24-24H56C42.745 0 32 10.745 32 24v456H12c-6.627 0-12 5.373-12 12v20h448v-20c0-6.627-5.373-12-12-12zM128 76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76zm0 96c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40zm52 148h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12zm76 160h-64v-84c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v84zm64-172c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40z"/></svg>',
      svg: '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-map-marker-alt fa-w-12 fa-9x"><path fill="#303030" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z" class=""></path></svg>',
      mapStyles: [
        { elementType: "geometry", stylers: [{ color: "#f5f5f5" }] },
        {
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        {
          elementType: "labels.text.fill",
          stylers: [{ color: "#616161" }],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [{ color: "#f5f5f5" }],
        },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels.text.fill",
          stylers: [{ color: "#bdbdbd" }],
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.fill",
          stylers: [{ color: "#f2f2f2" }],
        },
        {
          featureType: "landscape.natural",
          elementType: "geometry.fill",
          stylers: [{ color: "#ffffff" }],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [{ color: "#eeeeee" }],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#757575" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#e5e5e5" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9e9e9e" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#ffffff" }],
        },
        {
          featureType: "road",
          elementType: "geometry.fill",
          stylers: [{ color: "#c3c3c3" }],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.text.fill",
          stylers: [{ color: "#757575" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#dadada" }],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#616161" }],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9e9e9e" }],
        },
        {
          featureType: "transit.line",
          elementType: "geometry",
          stylers: [{ color: "#e5e5e5" }],
        },
        {
          featureType: "transit.station",
          elementType: "geometry",
          stylers: [{ color: "#eeeeee" }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#c9c9c9" }],
        },
        {
          featureType: "water",
          elementType: "geometry.fill",
          stylers: [{ color: "#d2d6de" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9e9e9e" }],
        },
      ],
    };
  },
  computed: {
    google: gmapApi,
    showMapError() {
      return this.errors.length > 0;
    },
    popFastigheter() {
      return this.fastigheter.map((f) => {
        let hyresobjekt = this.hyresobjekt.filter((h) => {
          return h.fastighet === f._id;
        });
        return Object.assign({}, f, { hyresobjekt });
      });
    },
    placedFastigheter() {
      return this.popFastigheter.filter((f) => {
        return f.location && f.location.lng && f.location.lat;
      });
    },
    unplacedFastigheter() {
      return this.popFastigheter.filter((f) => {
        return !(f.location && f.location.lng && f.location.lat);
      });
    },
    markers() {
      if (!this.popFastigheter) return [];

      return this.placedFastigheter.map((rawFastighet, index) => {
        let opened = this.openMarkerIndex === index;
        let {
          location: { lat, lng },
          image,
        } = rawFastighet;
        let imageUrl = image && image.location;
        // Make sure lat and lng are numbers and not strings!
        let fastighet = Object.assign({}, rawFastighet, {
          location: { lat: parseFloat(lat), lng: parseFloat(lng) },
        });
        return {
          icon: "building",
          fastighet,
          opened,
          imageUrl,
        };
      });
    },
    bounds() {
      // return false
      if (!this.google) return false;
      let bounds = new this.google.maps.LatLngBounds();
      if (this.markers.length > 0) {
        this.markers.forEach((m) => bounds.extend(m.fastighet.location));
      } else {
        // Center on Sweden if no markers are present
        bounds.extend({ lat: 50, lng: 10 });
        bounds.extend({ lat: 70, lng: 30 });
      }
      return bounds;
    },
  },
  watch: {
    bounds() {
      if (this.autoZoom && this.placingInProgress) this.fitBounds();
    },
  },
  mounted() {
    // It's required to call this.geocodeAddress after the map has been created as this.geocoder() will not be ready until then.
    // It might be better to await the geocoder itself, but I don't know how and this seems to work.
    let map = this.$refs.map;
    map.$mapPromise.then(() => {
      this.fitBounds();
    });
  },
  methods: {
    async geocodeUnplacedFastigheter() {
      this.placingInProgress = true;
      this.errors = [];
      this.results = [];
      for (const fastighet of this.fastigheter) {
        if (!fastighet.postadress || !fastighet.stad) continue;
        if (
          fastighet.location &&
          fastighet.location.lng &&
          fastighet.location.lat
        )
          continue;
        try {
          let { lat, lng } = await this.geocode(
            `${fastighet.postadress} ${fastighet.stad}`
          );
          let update = await this.$axios.patch(
            `/hyresvard/fastigheter/${fastighet._id}`,
            { location: { lat, lng } }
          );
          this.results.push(update);
          await this.$store.dispatch("fastigheter/fetch");
        } catch (error) {
          this.errors.push({ reason: error, fastighet });
          this.$store.dispatch("clientNotifications/create", {
            title: `${fastighet.postadress} ${fastighet.stad}: ${error}`,
            type: "warning",
          });
        }
      }
      this.placingInProgress = false;
      this.createSuccessNotif();
    },
    createSuccessNotif() {
      let str = `${this.results.length} fastigheter placerades på kartan.`;
      this.$store.dispatch("clientNotifications/create", {
        title: str,
        type: "success",
      });
    },
    async openMarker(index) {
      if (this.openMarkerIndex === index) this.openMarkerIndex = -1;
      else this.openMarkerIndex = index;
      if (this.openMarkerIndex > -1) {
        this.loadingHyresobjekt = true;
        const fastighetId = this.markers[this.openMarkerIndex].fastighet._id;
        try {
          const res = await this.$axios.get(
            `/hyresvard/fastigheter/${fastighetId}/hyresobjekt`
          );
          this.hyresobjekt = res.data;
        } finally {
          this.loadingHyresobjekt = false;
        }
      }
    },
    fitBounds() {
      let padding = 100;
      this.$refs.map.fitBounds(this.bounds, padding);
      if (this.markers.length === 1) {
        this.$refs.map.$mapObject.setZoom(13);
      }
    },
    updateMarkerCoords(e) {
      let lat = e.latLng.lat();
      let lng = e.latLng.lng();
      this.$emit("update:markerCoords", { lat, lng });
    },
  },
};
</script>
<style lang="scss" scoped>
.info-window {
  max-width: 300px;
}
</style>
