export const state = () => ({
  avtal: [],
});

export const actions = {
  async fetchAvtal({ commit }) {
    const res = await this.$axios.get(`hyresvard/todo`);
    commit("SET_DATA", res.data);
    return res.data;
  },
};
export const mutations = {
  SET_DATA: (state, data) => {
    state.data = data;
  },
};
