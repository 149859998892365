<template lang="pug">

div.filter-bar(style="w-100")
    b-dropdown(
      text="123" 
      variant="primary" 
      class="my-2 mr-2" 
      no-caret 
      left
      no-flip
      toggle-class=""
      menu-class="nested-menu"
      ref="menuBtn"
      id="dropdownButton"
      :disabled="propAppliedFilters.length >= MAX_FILTERS_ALLOWED"
      @hidden="resetFilterTempData"
      v-if="!hideFilterDropdown"
    )
      template(#button-content)
        | Filter
        fa.ml-1.d-xl-inline(icon="plus")
        
      //- First dropdown  
      div.items-container
        div.items-card
          a.dropdown-item(
            tabindex="1"
            v-for="field in dropdownFilterList" 
            v-if="field" @click="toggleFilterMenu(field)" 
            :class="{'active': currentFilterField?.key == field.key, 'disabled': !field.canAdd }"
          ) 
            | {{field.label}}
      filter-menu(
        :prop-applied-filters="propAppliedFilters"
        :current-filter-field="currentFilterField"
        :currentDefaultValue.sync="filterToAdd"
        @addFilter="formSubmit"
        v-if="popoverTarget === null"
      )

    //- The applied filters  
    b-button.filterDisplayButton.m-1(
      v-for="(filter, list_idx) in displayFilters" :key="list_idx"
      size="md" 
      :class="['filter-button', {'preview': filter.preview}]" 
      variant="light"
      :id="'filterDisplayButton_'+(filter.preview ? 'preview_'+list_idx : filter.index)"
      @click="filter.preview ? openFilterPreview(filter.key, list_idx) : openFilterWith(filter.index)"
    )
      span.mr-1(v-if="filter.preview"): fa(icon="circle-plus")
      span.filterItem-text {{filter.label}}: #[b {{filter.value}}]
      span.delete-button: fa.ml-2(icon="delete-left" @click="(event) => removeFilterButton(event, filter.index)" v-if="!filter.preview") 
      
    b-popover#filterPopoverMenu(
      v-if="popoverTarget !== null"
      :target="popoverTarget"
      triggers="click"
      :show="!!popoverTarget"
      placement="auto"
      container="container-fluid"
      :key="popoverTarget"
      ref="popover"
    )
      template(#title)
        b-button( @click="resetFilterTempData" class="close" aria-label="Close")
          span(class="d-inline-block" aria-hidden="true") &times;
        | Filter: #[b {{ currentFilterField?.label }} ]

      filter-menu(
        :prop-applied-filters="propAppliedFilters"
        :current-filter-field="currentFilterField"
        :currentDefaultValue.sync="filterToAdd"
        :is-popover="true"
        @addFilter="formSubmit"
      )

</template>
<script>
import { filterTypes } from "./types.js";
import FilterMenu from "./FilterMenu.vue";

export default {
  components: {
    FilterMenu,
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
      required: false,
    },
    previewFields: {
      type: Array,
      default: () => [],
      required: false,
    },
    usesApi: {
      type: Boolean,
      default: false,
    },
    propAppliedFilters: {
      type: Array,
      default: () => [],
      required: true,
    },
    hideFilterDropdown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentFilterField: null,
      appliedFilters: [],
      filterToAdd: null,
      editingFilter: null,
      MAX_FILTERS_ALLOWED: 5,
      popoverTarget: null,
    };
  },
  computed: {
    filterableFields() {
      return this.fields.filter((item) => item.filter);
    },
    dropdownFilterList() {
      const exceedLimit = this.keysFiltered.length > this.MAX_FILTERS_ALLOWED;
      return this.filterableFields
        .filter((field) => !field.filter.hide)
        .map((field) => {
          return Object.assign(
            {
              canAdd:
                !(this.usesApi && this.keysFiltered.includes(field.key)) &&
                !exceedLimit,
            },
            field
          );
        });
    },
    keysFiltered() {
      return this.propAppliedFilters.map((item) => item.key);
    },
    displayFilters() {
      const formattedDisplay = this.formattedAppliedFilters.map((item) =>
        Object.assign(item, { preview: false })
      );
      const showBefore = [];

      this.previewFields.forEach((filterKey) => {
        let filter = this.fields.find((item) => item.key === filterKey);
        if (!filter) return;

        let index = formattedDisplay.findIndex(
          (item) => item.key == filter.key
        );
        if (index >= 0) {
          showBefore.push(formattedDisplay[index]);
          formattedDisplay.splice(index, 1);
        } else {
          showBefore.push({
            label: filter.label,
            value: "Välj",
            key: filter.key,
            preview: true,
          });
        }
      });

      return [...showBefore, ...formattedDisplay];
    },
    formattedAppliedFilters() {
      return this.propAppliedFilters
        .map((item, index) => {
          let field = this.fields.find((field) => field.key == item.key);
          if (!field) return item;

          // do not display if it should be hidden
          if (field.filter.hide) return null;

          let formattedItem = {};
          formattedItem.label = field?.label;
          formattedItem.value = item.value;
          formattedItem.key = field.key;
          formattedItem.index = index;

          if (field.filter?.type == "enum") {
            let enumItem = field.filter.values.find(
              (enumOption) => enumOption.value == item.value
            );
            // set enum text value to label
            formattedItem.value = enumItem.text;
          }

          if (field.filter?.type == "date") {
            let text = `${item.value.start}`;
            if (item.value.start != item.value.end) {
              text += ` - ${item.value.end}`;
            }

            if (item.value.start && !item.value.end) {
              text = `Från ${item.value.start}`;
            } else if (!item.value.start && item.value.end) {
              text = `Senast ${item.value.end}`;
            }
            formattedItem.value = text;
          }

          if (field.filter?.type == "number") {
            let text = `${item.value.min}`;

            if (item.value.min != item.value.max) {
              text += ` - ${item.value.max}`;
            }

            if (item.value.min && !item.value.max) {
              text = `Lika eller mer än ${item.value.min}`;
            } else if (!item.value.min && item.value.max) {
              text = `Lika eller mindre än ${item.value.max}`;
            }
            formattedItem.value = text;
          }

          return formattedItem;
        })
        .filter((item) => item); // remove all null values
    },
  },
  mounted() {
    this.$root.$on("bv::dropdown::show", () => {
      if (this.popoverTarget) {
        this.resetFilterTempData();
      }
    });
  },
  methods: {
    formSubmit(key, value, isRange) {
      this.addFilter(key, value, isRange);
      this.$refs.menuBtn?.hide();
    },
    toggleFilterMenu(field) {
      if (field.key == this.currentFilterField?.key) {
        this.currentFilterField = null;
        this.filterToAdd = null;
      } else {
        // Can not add same key twice if api is being used
        if (
          (this.usesApi && this.keysFiltered.includes(field.key)) ||
          this.keysFiltered.length > this.MAX_FILTERS_ALLOWED
        )
          return;

        this.setupFilterMenu(field.key);
        this.$refs.menuBtn.show();
      }
    },
    openFilterWith(index) {
      const target = "filterDisplayButton_" + index;
      const filter = this.propAppliedFilters[index];
      this.togglePopover(target, filter.key, filter.value, index);
    },
    openFilterPreview(fieldKey, previewIdx) {
      const target = "filterDisplayButton_preview_" + previewIdx;
      this.togglePopover(target, fieldKey, undefined);
    },
    togglePopover(target, fieldKey, value, editingIdx = null) {
      if (this.popoverTarget === target) {
        this.resetFilterTempData();
      } else {
        this.resetFilterTempData();
        this.$nextTick(() => {
          this.editingFilter = editingIdx;
          this.setupFilterMenu(fieldKey, value);
          this.popoverTarget = target;

          // Enough time for popover to render
          setTimeout(() => {
            document.querySelector(".filter-menu input")?.focus();
          }, 100);
        });
      }
    },
    setupFilterMenu(fieldKey, value) {
      const field = this.filterableFields.find((item) => item.key === fieldKey);
      if (!field) {
        console.log("could not find field");
        return;
      }

      if (typeof value === "undefined") {
        // get default from types
        const fieldType = field.filter.type;
        const fieldDefault = filterTypes[fieldType].default;
        this.filterToAdd =
          typeof fieldDefault === "object" && fieldDefault !== null
            ? Object.assign({}, fieldDefault)
            : fieldDefault;
        this.editingFilter = null;
      } else {
        this.filterToAdd =
          typeof value === "object" && value != null
            ? Object.assign({}, value)
            : value;
      }

      this.currentFilterField = field;
    },
    addFilter(key, value, isRange = false) {
      let field = this.fields.find((item) => item.key == key);
      if (!field) {
        console.log(`key: '${key}' not found`);
        return;
      }
      let filter = field.filter;
      const tempAppliedFilters = Array.from(this.propAppliedFilters);

      if (filterTypes[filter.type]) {
        let filterItem = filterTypes[filter.type].item(
          field,
          key,
          value,
          isRange
        );
        if (filterItem) {
          if (this.editingFilter !== null) {
            tempAppliedFilters[this.editingFilter] = filterItem;
          } else {
            tempAppliedFilters.push(filterItem);
          }
        }
      } else {
        return;
      }

      // cleanup
      this.resetFilterTempData();

      // Update Event
      this.$emit("update:propAppliedFilters", tempAppliedFilters);
      this.$emit("change");
    },
    removeFilterButton(event, index) {
      event.stopPropagation();
      this.removeAppliedFilter(index);
    },
    removeAppliedFilter(index, update = true) {
      this.resetFilterTempData();
      this.$nextTick(() => {
        const tempAppliedFilters = Array.from(this.propAppliedFilters);
        tempAppliedFilters.splice(index, 1);
        // Update Event
        this.$emit("update:propAppliedFilters", tempAppliedFilters);
        if (update) {
          this.$emit("change");
        }
      });
    },
    resetFilterTempData() {
      // For some reason, if we do not emit the below events then the popover will re-emerge despite being destroyed
      this.$root.$emit("bv::disable::popover", "filterPopoverMenu");
      this.$root.$emit("bv::hide::popover", "filterPopoverMenu");

      this.popoverTarget = null;

      this.currentFilterField = null;
      this.filterToAdd = null;
      this.editingFilter = null;
    },
  },
};
</script>
<style lang="scss">
.filter-bar {
  .dropdown-menu.show.nested-menu {
    display: flex;
    background: unset;
    border: unset;
    padding: 0;
    /* flex-direction: column; */
    gap: 5px;

    .items-card {
      background-color: #fff;
      padding: 5px;
      border: 1px solid rgba(0, 0, 0, 0.125);

      > a.dropdown-item,
      > div.dropdown-item {
        cursor: pointer;
      }
    }
  }

  .filterDisplayButton {
    cursor: pointer;
    background-color: #fff;

    > span.delete-button {
      cursor: pointer;
      &:hover > svg {
        transform: scale(1.05);
      }
    }
  }

  @media (max-width: 505px) {
    .dropdown-menu.show.nested-menu {
      flex-direction: column;
      gap: 15px;
    }
  }
}
</style>
