<template lang="pug">
b-navbar(toggleable="sm", type="dark", variant="dark")
  .sidebar-toggler(v-if="$route.path !== '/hyresvard/intro'")
    b-navbar-brand.sidebar-toggler(
      href="#",
      @click="toggleSideBar()",
      :title="sidebarCollapsed ? 'Open Sidebar' : 'Close Sidebar'"
    )
      fa.sidebar-toggler(
        :icon="sidebarCollapsed ? 'chevron-right' : 'chevron-left'"
      )
  div
    b-button(
      variant="primary",
      @click="showGlobalSearch",
      v-if="showMainSearchButton"
    ): fa.h-100(
      icon="magnifying-glass",
      size="lg"
    )
    b-navbar-toggle.custom-navbar-toggle.btn.btn-primary(target="nav_collapse")
      fa.h-100(icon="bars", size="lg")
  b-collapse#nav_collapse.min-width-0(is-nav): b-navbar-nav.ml-auto.flex-shrink-1.min-width-0
    b-input-group.flex-shrink-1.min-width-0.flex-nowrap
      el-select.hyresvard-select(
        v-if="hyresvardar && hyresvardar.length",
        @change="selectHyresvard",
        :value="selectedHyresvard",
        value-field="_id",
        text-field="displayName",
        filterable
      )
        el-option(
          v-for="hv in hyresvardarOptions",
          :key="hv._id",
          :label="hv.displayName",
          :value="hv._id"
        )
      template(
        v-if="hyresvardar.length > 0 && (!selectedHyresvard || hasPermission(['write:administrator']))"
      )
        b-button(
          @click="navigateToHvSettings",
          variant="primary",
          v-b-tooltip.hover,
          :title="currentHyresvard ? `Inställningar för ${currentHyresvard && currentHyresvard.displayName}` : 'Visa lista med alla hyresvärdar'"
        )
          fa.h-100(:icon="currentHyresvard ? 'gear' : 'gears'")

      template(v-if="!showMainSearchButton")
        b-button(variant="primary", @click="showGlobalSearch"): fa.h-100(
          icon="magnifying-glass"
        )
      template
        b-button.flex-shrink-1.min-width-0.overflow-hidden(
          variant="primary",
          @click="$router.push({ name: 'hyresvard-fakturering' })",
          :style="currentHyresvard ? '' : 'opacity: 0; visibility: hidden;'"
        )
          fa.mr-2.d-none.d-xl-inline(icon="credit-card")
          span {{ ((currentHyresvard && currentHyresvard.plan) || "FREE") | capitalize }}
    b-nav-item-dropdown.ml-4(right)
      template(slot="button-content")
        span(style="text-transform: none")
          fa.mr-1(icon="user-shield", v-if="accountType === 'admin'")
          | {{ userDisplayName }}
      b-dropdown-item(
        @click.prevent="$root.$emit('bv::show::modal', 'newHyresvard')"
      )
        fa(icon="plus")
        |
        | Skapa ny hyresvärd
      b-dropdown-item(@click.prevent="openGuide()") Öppna guiden
      b-dropdown-item(:to="{ name: 'hyresvard-profil' }") Profil
      b-dropdown-item(href="https://www.tenfast.se/support", target="_blank") Support
      b-dropdown-item(@click.prevent="logout()") Logga ut
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      isRefreshing: false,
      windowWidth: null,
    };
  },
  computed: {
    ...mapState({
      email: (state) => state.me.data.email,
      accountType: (state) => state.accountType,
      hyresvardar: (state) => state.hyresvardar.data,
      sidebarCollapsedLg: (state) => state.sidebar.collapsedLg,
      sidebarCollapsedSm: (state) => state.sidebar.collapsedSm,
      selectedHyresvard: (state) => state.selectedHyresvard,
    }),
    ...mapGetters({
      currentHyresvard: "currentHyresvard",
      hasPermission: "hasPermission",
    }),
    hyresvardarOptions() {
      let hyresvardar = this.hyresvardar.slice();
      if (this.hyresvardar.length > 1)
        hyresvardar.unshift({
          displayName: `Alla hyresvärdar (${this.hyresvardar.length})`,
          _id: null,
        });
      return hyresvardar;
    },
    userDisplayName() {
      if (!this.email) return "";
      let split = this.email.split("@");
      if (split.length === 2) {
        return split[0].split(".")[0];
      } else {
        return this.email;
      }
    },
    sidebarCollapsed() {
      if (this.windowWidth >= 768) return this.sidebarCollapsedLg;
      else return this.sidebarCollapsedSm;
    },
    showMainSearchButton() {
      return this.windowWidth < 576;
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    this.windowWidth = window.innerWidth;
  },
  methods: {
    navigateToHvSettings() {
      const route = this.selectedHyresvard
        ? {
            name: "hyresvard-hyresvardar-id",
            params: { id: this.selectedHyresvard },
          }
        : { name: "hyresvard-hyresvardar" };
      this.$router.push(route);
    },
    toggleSideBar() {
      if (this.windowWidth >= 768) {
        this.toggleLg();
      } else {
        this.toggleSm();
      }
    },
    ...mapMutations({
      toggleSm: "sidebar/toggleSm",
      toggleLg: "sidebar/toggleLg",
    }),
    selectHyresvard(id) {
      this.$store.dispatch("setSelectedHyresvard", {
        hvToSelect: id,
        redirectFix: true,
      });
    },
    async logout() {
      await this.$auth.logout();
      this.$store.commit("SET_SELECTED_HYRESVARD", null);
    },
    openGuide() {
      this.$root.$emit("bv::show::modal", "guide-modal");
    },
    showGlobalSearch() {
      this.$root.$emit("bv::show::modal", "global-search-modal");
    },
  },
};
</script>
<style lang="scss">
@media screen and (min-width: 768px) {
  .navbar {
    height: 50px;
  }
}
.navbar {
  color: #eee;
  .btn,
  select {
    height: 100% !important;
    // margin: 5px 0;
  }
}
.navbar-expand-md .navbar-nav {
  flex-flow: row wrap;
  justify-content: center;
  // display: flex;

  @media screen and (max-width: 768px) {
    // margin-top: 8px;
    width: 100%;
  }
  .dropdown-menu {
    position: absolute;
  }
}
.custom-navbar-toggle {
  border: none;
  color: white !important;
  button {
    :focus {
      border: none;
    }
  }
}
.hyresvard-select {
  border-radius: 0;
  min-width: 210px;
  input {
    background-color: black;
    color: white;
  }
  .el-input__inner {
    border: 1px solid black;
    border-left: 3px solid white;
    border-radius: 0.5rem;
    &:focus,
    &:hover {
      border: 1px solid black !important;
      border-left: 3px solid white !important;
    }
  }
}
.dropdown-item {
  padding: 0.5rem 1.5rem;
}
</style>
