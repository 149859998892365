<template lang="pug">
div(v-b-tooltip.html.hover, :title="title")
  b-badge.mr-1(variant="info") {{ chosenFeature.name }}
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    feature: {
      type: String,
      required: true,
    },
    clarify: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      featuresMap: "hyresvardar/featuresMap",
    }),
    chosenFeature() {
      return this.featuresMap[this.feature];
    },
    title() {
      return this.clarify
        ? `<strong>Tillägg:</strong>
          <br>
          <p>${this.chosenFeature.promotionDescription}</p>
          <br>
          ${this.$tt("tips.featureActivation")}

      `
        : this.chosenFeature.promotionDescription;
    },
  },
  mounted() {},
  methods: {},
};
</script>
