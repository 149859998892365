<template lang="pug">
b-modal(
  size="sm",
  :title="title",
  :visible="value",
  @change="updateValue",
  hide-footer,
  hide-header-close
)
  p {{ text }}
  b-button.float-right.w-100(@click="proceed", variant="success") Gå vidare
  | &nbsp;
  b-button.float-left.w-100(@click="cancel", variant="danger") Avbryt
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "Är du säker?",
    },
    text: {
      type: String,
      default: "Det går inte att ångra.",
    },
    value: {
      type: Boolean,
      default: true,
    },
    action: {
      type: Function,
      default: () => null,
    },
  },
  methods: {
    proceed() {
      this.action(); // Run the passed action
      this.$emit("proceed");
    },
    cancel() {
      this.$emit("cancel");
      this.$emit("input", false);
    },
    updateValue(visible) {
      this.$emit("input", visible);
    },
  },
};
</script>
