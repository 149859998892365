// Expects string array or string with action:subject
export function accessMiddleware(checks) {
  const normalizedFeatureList = validateChecks(checks);

  return async function ({ store, redirect }) {
    if (store.state.loading) {
      await store.dispatch("init");
    }

    const hasAccess = accessCheck(
      store.state.permissionMap,
      normalizedFeatureList
    );

    if (!hasAccess) {
      redirect({ name: "hyresvard-oversikt" });
    }
  };
}

export function accessCheck(permissionMap, checks) {
  const hasAccess = checks.every((check) => {
    const data = check.split(":");
    return (
      data.length === 2 &&
      typeof permissionMap[data[1]] === "object" &&
      typeof permissionMap[data[1]][data[0]] === "boolean" &&
      permissionMap[data[1]][data[0]]
    );
  });
  return hasAccess;
}

function validateChecks(checks) {
  if (!Array.isArray(checks) && typeof checks !== "string") {
    throw Error(
      "checkFeatureAccess middleware expects either an array or a string"
    );
  }
  return typeof checks === "string" ? [checks] : checks;
}
