import Vue from "vue";
import { ClientTable } from "vue-tables-2";

// https://github.com/matfish2/vue-tables-2

let axios = require("axios");
let options = {
    requestFunction: function (data) {
      return axios
        .get(this.url, {
          params: data,
        })
        .catch(
          function (e) {
            this.dispatch("error", e);
          }.bind(this)
        );
    },
    filterByColumn: true,
    texts: {
      count:
        "Showing {from} to {to} of {count} records|{count} records|One record",
      first: "Första",
      last: "Sista",
      filter: "Filter:",
      filterPlaceholder: "Sök...",
      limit: "Artiklar:",
      page: "Sida:",
      noResults: "Ingen data.",
      filterBy: "Sök {column}",
      loading: "Laddar...",
      defaultOption: "Alla",
      columns: "Kolumner",
    },
    perPage: "50",
    pagination: {
      // dropdown: true,
    },
    skin: "table-striped table-hover",
    // Setting preserveState to true saves sorting, but seems to cause reactivity issues. Disabled it for now.
    // preserveState: true,
  },
  // Setting this to true seems to cause problems after updating to nuxt 2.x.x
  useVuex = false,
  theme = "bootstrap4",
  template = "default";

Vue.use(ClientTable, options, useVuex, theme, template);
