<template lang="pug">
b-form(@submit.prevent="onSubmit")
  b-modal#hyresobjektModal(
    @hidden="hidden",
    @show="fetchFastigheter",
    size="lg",
    :title="type === 'new' ? 'Skapa nytt hyresobjekt' : 'Redigera hyresobjekt'",
    static,
    lazy,
    no-close-on-backdrop
  )
    b-row
      b-col.pl-0: b-form-group(label="Typ")
        b-select(:options="types", v-model="typ", text-field="label")
      b-col.pr-0: b-form-group(label="Kvadratmeter")
        b-form-input(type="number", step="0.1", min="0", v-model="kvm")
    b-row
      b-col.pl-0(lg="6", md="12")
        b-form-group(
          v-if="typ === 'bostad' || typ === 'villa'",
          label="Bostadstyp"
        )
          b-input-group
            b-form-input(
              type="number",
              min="0.5",
              step="0.5",
              v-model="roomCount",
              required
            )
            b-select(
              :options="bostadTypes",
              v-model="bostadType",
              text-field="label",
              required
            )
        b-form-group(v-else-if="typ === 'parkering'", label="Parkering för")
          b-select(
            :options="parkeringTypes",
            v-model="parkeringType",
            text-field="label",
            required
          )
      b-col.pr-0(lg="6", md="12")
        b-form-group(
          label="Referenshyra",
          description=`Referenshyran anges som en månadshyra och används i avtalsskaparen.
						Om avisering sker exempelvis kvartalsvis kan hyran korrigeras när avtalet skapas.
					`
        )
          b-input-group(append="kr / månad"): b-input(
            type="number",
            v-model="hyra"
          )
    b-form-group(
      label="Fastighet",
      description="Val av fastighet fyller automatiskt i postadress och postnummer."
    )
      el-select(
        v-model="fastighet",
        @change="fastighetChange",
        placeholder="Välj fastighet",
        filterable,
        clearable
      )
        el-option(
          v-for="f in fastigheter",
          :key="f._id",
          :value="f._id",
          :label="f.fastighetsbeteckning"
        )
    b-form-group(label="Postadress")
      b-form-input(type="text", v-model="postadress")
    b-row
      b-col.pl-0: b-form-group(label="Postnummer")
        b-form-input(type="text", v-model="postnummer")
      b-col.pr-0: b-form-group(label="Stad")
        b-form-input(type="text", v-model="stad")
    b-row
      b-col.pl-0: b-form-group(
        label="Nationellt lgh-nummer",
        description="Det nationella lägenhetsnummret (kallas ibland för \"Skatteverkets lägenhetsnummer\"). Numret bildar tillsammans med adressen en unik identitet för varje bostad."
      )
        b-form-input(type="number", v-model="skvNummer")
      b-col.pr-0: b-form-group(
        label="Objektnummer",
        description="Utöver det nationella lägenhetsnummret finns ibland även en separat beteckning skapad av bostadsrättsföreningen eller fastighetsägaren. Detta nummer kallas för objektnummer."
      )
        b-form-input(type="text", v-model="nummer")
    b-form-group(
      label="Populärnamn",
      description="Namn som är allmänt känt och används som komplement till addressen för hyresobjektet."
    )
      b-form-input(type="text", v-model="commonName")
    b-form-group(
      label="Beskrivning",
      description="Frivilligt. Kan användas för att visa på egen hemsida."
    )
      b-form-textarea(
        v-model="description",
        placeholder="En beskrivande text av hyresobjektet"
      )
    .w-100(slot="modal-footer")
      b-button.float-right(type="submit", variant="primary")
        span(v-if="type === 'new'") Skapa
        span(v-else) Spara
      b-button.float-left(@click="closeModal") Avbryt
</template>

<script>
import { mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  data() {
    return {
      fastigheter: [],
    };
  },
  computed: {
    ...mapState({
      types: (state) => state.hyresobjekt.types,
      bostadTypes: (state) => state.hyresobjekt.bostadTypes,
      parkeringTypes: (state) => state.hyresobjekt.parkeringTypes,
      type: (state) => state.hyresobjekt.type,
    }),
    ...mapFields({
      typ: `hyresobjekt.form.typ`,
      hyra: `hyresobjekt.form.hyra`,
      roomCount: `hyresobjekt.form.roomCount`,
      bostadType: `hyresobjekt.form.bostadType`,
      parkeringType: `hyresobjekt.form.parkeringType`,
      hyresgast: `hyresobjekt.form.hyresgast`,
      postadress: `hyresobjekt.form.postadress`,
      postnummer: `hyresobjekt.form.postnummer`,
      stad: `hyresobjekt.form.stad`,
      commonName: `hyresobjekt.form.commonName`,
      hyresvard: `hyresobjekt.form.hyresvard`,
      kvm: `hyresobjekt.form.kvm`,
      nummer: `hyresobjekt.form.nummer`,
      skvNummer: `hyresobjekt.form.skvNummer`,
      fastighet: `hyresobjekt.form.fastighet`,
      description: `hyresobjekt.form.description`,
    }),
  },
  methods: {
    closeModal() {
      this.$root.$emit("bv::hide::modal", "hyresobjektModal");
    },
    async onSubmit() {
      let data = await this.$store.dispatch(`hyresobjekt/${this.type}`);
      if (this.type === "new") {
        this.$root.$emit("newHyresobjekt", data);
      } else {
        this.$root.$emit("editHyresobjekt", data);
      }
      this.closeModal();
    },
    fastighetChange(id) {
      if (id) {
        const fastighet = this.fastigheter.find((f) => f._id === id);
        this.$store.dispatch("hyresobjekt/fillFastighet", fastighet, this.type);
      } else {
        this.$store.dispatch("hyresobjekt/fillFastighet", false, this.type);
      }
    },
    hidden() {
      this.$store.commit("hyresobjekt/RESET_FORM");
    },
    async fetchFastigheter() {
      const res = await this.$axios.get("/hyresvard/fastigheter");
      this.fastigheter = res.data;
    },
  },
};
</script>
