import merge from "deepmerge";

const defaultPaymentMethods = () => ({
  swish: {
    enabled: false,
    number: "",
  },
  bankkonto: {
    enabled: false,
    number: "",
  },
  bankgiro: {
    enabled: false,
    number: "",
    ocr: {
      enabled: false,
      controlLevel: undefined,
      forcedLength: null,
    },
  },
  international: {
    enabled: false,
    bic: "",
    iban: "",
    address: "",
  },
});
export const state = () => ({
  data: [],
  new: {
    name: {
      first: null,
      last: null,
    },
    isCompany: true,
    company: null,
    firmatecknare: [],
    phone: null,
    postadress: null,
    postnummer: null,
    stad: null,
    momsnummer: null,
    email: null,
    typeOptions: null,
    autoSendHyraNotifications: false,
    pdfAttachmentOnHyraNotifications: true,
    individualRentEmailPdfAttachment: true,
    idbeteckning: "",
    paymentMethods: defaultPaymentMethods(),
    generalSettings: null,
  },
  edit: null /*{
    name: {},
    idbeteckning: "",
    firmatecknare: [],
    autoSendHyraNotifications: false,
    paymentMethods: defaultPaymentMethods(),
  }*/,
  editRole: null,
  typeOptions: [
    {
      text: "Företag",
      value: true,
    },
    {
      text: "Privatperson",
      value: false,
    },
  ],
  features: {
    mail: {
      adminDescription:
        "Aktiverar Ekopost tillägget. (Extrakostnader tillkommer)",
      promotionDescription:
        "Med detta tillägg kan du skicka avier och meddelande via brev och kivra.",
      name: "Brev+Digitalt",
      key: "ekopost",
    },
    bank: {
      adminDescription: "Aktivera (Nordigen) Bankintegration",
      promotionDescription:
        "Automatiserar betalningsavprickning genom en bankkoppling.",
      name: "Nyhet",
      key: "bank",
    },
  },
});

export const actions = {
  async fetch({ commit }) {
    const res = await this.$axios.get(`hyresvard/hyresvardar`);
    commit("SET_DATA", res.data);
    return res.data;
  },
  async new({ commit, dispatch, state }) {
    const res = await this.$axios.post(`hyresvard/hyresvardar`, {
      ...state.new,
    });
    commit("RESET_NEW");
    dispatch(
      "clientNotifications/create",
      { title: "Ny hyresvärd skapades.", type: "success" },
      { root: true }
    );

    await dispatch("fetch"); // Alternativt fetcha endast ny hyresvärd
    dispatch(
      "setSelectedHyresvard",
      { hvToSelect: res.data._id, redirectFix: true },
      { root: true }
    );
  },
  async updateOne({ commit, state }, newHv) {
    commit(
      "SET_DATA",
      state.data.map((hv) => {
        if (hv._id === newHv._id) return merge({}, newHv);
        else return hv;
      })
    );
  },
  async fetchAndUpdateOne({ dispatch }, id) {
    const res = await this.$axios.get(`hyresvard/hyresvardar/${id}`);
    await dispatch("updateOne", res.data);
  },
  async delete({ dispatch }, id) {
    await this.$axios.delete(`/hyresvard/hyresvardar/${id}`);
    await dispatch("fetch");
  },
  setEdit({ commit, state }, id) {
    let hyresvard = state.data.find((h) => h._id === id);
    commit("SET_EDIT", hyresvard);
  },
  async edit({ dispatch, state }) {
    const res = await this.$axios.patch(
      `/hyresvard/hyresvardar/${state.edit._id}`,
      {
        ...state.edit,
      }
    );
    dispatch(
      "clientNotifications/create",
      { title: "Hyresvärden uppdaterades.", type: "success" },
      { root: true }
    );
    await dispatch("updateOne", res.data);
    return res.data;
  },
};

export const mutations = {
  SET_DATA: (state, data) => {
    state.data = data;
  },
  SET_EDIT: (state, data) => {
    state.edit = data === null ? data : merge({}, data);
  },
  RESET_NEW: (s) => {
    const initial = state();
    s.new = initial.new;
  },
  ADD_NEW_FT: (state) => {
    state.new.firmatecknare.push({ idbeteckning: "" });
  },
  DELETE_NEW_FT: (state, data) => {
    state.new.firmatecknare.splice(data, 1);
  },
  ADD_EDIT_FT: (state) => {
    state.edit.firmatecknare.push({ idbeteckning: "" });
  },
  DELETE_EDIT_FT: (state, data) => {
    state.edit.firmatecknare.splice(data, 1);
  },
  SET_EDIT_ROLE: (state, data) => {
    state.editRole = data;
  },
};

export const getters = {
  featuresMap: (state) => {
    const map = {};
    Object.keys(state.features).forEach((key) => {
      const featureObj = state.features[key];
      map[featureObj.key] = featureObj;
    });
    return map;
  },
};
