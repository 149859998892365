<template lang="pug">
div
  clientNotifications
  b-container
    nuxt
</template>
<script>
import ClientNotifications from "~/components/ClientNotifications.vue";

export default {
  components: {
    ClientNotifications,
  },
};
</script>
<style lang="scss">
/* body {
  @media screen {
    max-height: 100vh;
    max-height: -webkit-fill-available;
  }
} */
.login-box {
  max-width: 360px;
  min-width: 200px;
  margin: 20px auto;
  /* max-height: 90vh; */
}
.login-box-body {
  background-color: white;
  padding: 20px;
}
.login-box-under {
  background-color: #f9f9f9;
}
.background {
  display: flex;
  justify-content: center;
}
.auto-y-margin {
  margin: auto 0;
}
.wrap {
  overflow-wrap: break-word;
}
@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.loading_placeholder {
  width: 100%;
  height: 1.2rem;
  background: rgb(224, 224, 224);
  display: inline-block;
  animation: pulse 800ms ease-in infinite alternate;
}
.loading_placeholder-2 {
  width: 80%;
  height: 1.2rem;
  background: rgb(224, 224, 224);
  display: inline-block;
  animation: pulse 800ms ease-in infinite alternate;
}
.loading_placeholder-3 {
  width: 35%;
  height: 1.2rem;
  background: rgb(224, 224, 224);
  display: inline-block;
  animation: pulse 800ms ease-in infinite alternate;
}
</style>
