import axios from "axios";

export default async function ({ store, $axios, redirect, route }) {
  if (store.getters["contractorUser/isActive"]) {
    return;
  }

  const url = new URL(window.location);
  try {
    // using axios instead $axios to avoid the toast error on fail
    const res = await axios.get(`${$axios.defaults.baseURL}contractor/me`, {
      params: {
        autotoken: url.searchParams.get("autotoken"),
      },
      withCredentials: true,
    });
    if (!res.data.length) {
      return;
    }

    const data = {
      email: res.data[0].email,
      contractors: res.data,
    };

    store.commit("contractorUser/SET_CONTRACTOR", data);
    await store.dispatch("contractorUser/init");

    // Remove autologin param
    url.searchParams.delete("autotoken");
    window.history.replaceState({}, null, url);

    if (route.name === "ansvarig-login") return redirect({ name: "ansvarig" });
  } catch (err) {
    console.error("err", err);
    if (route.name !== "ansvarig-login")
      return redirect({ name: "ansvarig-login" });
  }
}
