import Vue from "vue";
import { Select, DatePicker, Option, Input } from "element-ui";

// Import swedish locale files (the official swedish one is bad)
import svLang from "./element-sv-se";
import locale from "element-ui/lib/locale";
import "../assets/scss/element-ui.scss";

export default () => {
  // Use locale for all files
  locale.use(svLang);

  Vue.use(Select);
  Vue.use(DatePicker);
  Vue.use(Option);
  Vue.use(Input);
};
