<template lang="pug">
b-modal#guide-modal(
  title="En genomgång av TenFAST",
  ref="guide-modal",
  size="lg"
)
  h4
    fa.mr-2(icon="user-tie")
    span Hyresvärdar
  b-row
    b-col(md="6")
      p 
        | Allt i TenFAST är sorterat efter hyresvärdar. En hyresvärd kan vara ett företag eller privatperson.
        | Du kan se din aktiva hyresvärd längst upp till höger i dropdown-menyn.
        | För att redigera en hyresvärd tryck på kugghjulen brevid dropdown-menyn.
    b-col(md="6")
      b-img(src="/guide/hyresvardar.png", fluid)
  b-row
    b-col
      p Information som fylls i för hyresvärden hamnar på avtal och hyresavier.
        | Du specificerar även betalningsmetoder på hyresvärden istället för specifikt på varje avtal.
        | För BankID-signering används personnummret (för företag lägger man till firmatecknare) på hyresvärden.
  b-row
    b-col(md="6")
      p Om flera personer behöver tillgång till en hyresvärd kan man enkelt lägga till andra användare. Man kan välja mellan endast läsbehörighet och full tillgång.
    b-col(md="6")
      b-img(src="/guide/roller.png", fluid)
  hr
  h4
    fa.mr-2(icon="building")
    span Fastigheter & {{ " " }}
    fa.mr-2(icon="box-open")
    span Hyresobjekt
  p Lägg först in dina fastigheter. Fastigheterna kan automatiskt placeras ut på kartan i översikten.
  p Efter du har skapat dina fastigheter så lägg in dina hyresobjekt.
    | När du skapar hyresobjekt specificerar du vilken fastighet hyresobjektet tillhör.
    | Ett hyresobjekt kan vara exempelvis en bostad, kontor, parkeringsplats, m.m.
  p Fastigheter används främst för att ge struktur till hyresobjekt.
    |
    | Det går att skapa hyresobjekt som inte är kopplade till någon fastighet.
  hr
  h4
    fa.mr-2(icon="users")
    span Hyresgäster & Hyresgästportalen
  p Hyresgäster kan antingen vara privatpersoner eller företag. Om en e-post anges till hyresgästen finns möjligheten för hyresgästen att använda hyresgästsportalen.
    | I portalen kan avtal signeras med BankID och hyresavier betalas.
  hr
  h4
    fa.mr-2(icon="file-contract")
    span Avtal {{ " " }}
    fa.mr-2(icon="file-invoice-dollar")
    span Hyror & {{ " " }}
    fa.mr-2(icon="paper-plane")
    span Avisering
  p Ta hjälp av TenFASTs egna avtalsskapare eller ladda upp befintliga PDF:er.
    | Avtal kopplar ihop en hyresgäst med ett hyresobjekt och ligger som grund till hyror & avisering.
  p När ett nytt avtal skapas skickas ett e-postmeddelande till hyresgästen med en länk där de kan skapa ett konto till hyresgästportalen för att granska & signera avtalet.
  p Man kan skapar ett avtal utan en mall, då får man ett enkelt avtal med endast det mest nödvändiga innehållet.
    | Alternativt kan du skapa egna avtalsmallar. För att skapa en avtalsmall skapar du först tillägg. När du har skapat tillägg skapar du en mall där du kan plocka fritt mellan tilläggen.
  p Om signering sker via BankID blir avtalet signerat när alla parter har signerat. Om signering sker med penna och papper markerar man avtalet manuellt som signerat i systemet.
    | När ett avtal är signerat skapas hyror. Hyror aviseras antingen manuellt eller automatiskt via e-post.
    | Transaktioner kan läggas in manuellt på en hyra eller automatiskt med OCR-nummer.
  hr
  //- h4
  //- 	fa(icon="chart-bar").mr-2
  //- 	span Statistik
  //- p Här kan du se statistik om historiska intäkter och mer.
  //- hr
  h4
    fa.mr-2(icon="credit-card")
    span Fakturering
  p På faktureringssidan väljer du din TenFAST plan. För att testa systemet går det bra att använda <b>TenFAST FREE</b> där det tillåts max 5 hyresobjekt. Den vanligaste planen är <b>TenFAST PRO</b> där man kan ha upp till 50 hyresobjekt för 10 kr per hyresobjekt per månad. För <b>TenFAST PRO</b> görs betalningarna med kort via Stripe och sker månadsvis i efterskott. Har du mer än 50 hyresobjekt så är det <b>TenFAST ENTERPRISE</b> som gäller.

  p.font-italic.text-center.h5 Om du vill läsa detta igen så finns det tillgängligt i dropdown-menyn längst upp till höger av sidan.
  template(slot="modal-footer")
    b-button(@click="closeM()") Stäng
    b-button(
      :to="{ name: 'hyresvard-fakturering' }",
      @click="closeM()",
      variant="success"
    ) Se planer & pris
</template>

<script>
export default {
  methods: {
    closeM() {
      this.$bvModal.hide("guide-modal");
    },
  },
};
</script>
