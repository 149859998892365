export const state = () => ({
  dispatch: "",
  commit: "",
  data: null,
  modal: false,
});
export const actions = {
  openDialogue({ commit }, { string, data, type = "dispatch" }) {
    commit("SHOW_MODAL");
    if (type === "dispatch") {
      commit("SET_DISPATCH", string);
    }
    if (type === "commit") {
      commit("SET_COMMIT", string);
    }
    commit("SET_DATA", data);
  },
  proceed({ state, dispatch, commit }) {
    if (state.dispatch) {
      dispatch(state.dispatch, state.data, { root: true });
    }
    if (state.commit) {
      commit(state.commit, state.data, { root: true });
    }
    commit("RESET");
    commit("HIDE_MODAL");
  },
  cancel({ commit }) {
    commit("RESET");
    commit("HIDE_MODAL");
  },
};
export const mutations = {
  SET_DISPATCH: (state, data) => {
    state.dispatch = data;
  },
  SET_COMMIT: (state, data) => {
    state.commit = data;
  },
  SET_DATA: (state, data) => {
    state.data = data;
  },
  SHOW_MODAL: (state) => {
    state.modal = true;
  },
  HIDE_MODAL: (state) => {
    state.modal = false;
  },
  SET_MODAL: (state, data) => {
    state.modal = data;
  },
  RESET: (state) => {
    (state.dispatch = ""), (state.commit = "");
  },
};
