import merge from "deepmerge";

export const state = () => ({
  type: "new",
  form: {
    postadress: "",
    postnummer: "",
    stad: "",
    fastighetsbeteckning: "",
    location: {
      automatic: true,
      lng: "",
      lat: "",
    },
    description: null,
  },
  displayType: "square", // square (cards) or list (table)
  displayTypeEdited: false,
});

export const actions = {
  async new({ dispatch, state }) {
    const res = await this.$axios.post(`hyresvard/fastigheter`, {
      ...state.form,
    });
    dispatch(
      "clientNotifications/create",
      { title: "Ny fastighet skapades.", type: "success" },
      { root: true }
    );
    return res.data;
  },
  setEdit({ commit }, fastighet) {
    commit("SET_FORM", fastighet);
    commit("SET_TYPE", "edit");
  },
  async edit({ dispatch, state }) {
    const res = await this.$axios.patch(
      `hyresvard/fastigheter/${state.form._id}`,
      {
        ...state.form,
      }
    );
    dispatch(
      "clientNotifications/create",
      { title: "Fastigheten uppdaterades.", type: "success" },
      { root: true }
    );
    return res.data;
  },
  async delete({ dispatch }, id) {
    const res = await this.$axios.delete(`hyresvard/fastigheter/${id}`);
    dispatch(
      "clientNotifications/create",
      { title: "Fastigheten raderades.", type: "warning" },
      { root: true }
    );
    return res.data;
  },
};
export const mutations = {
  SET_FORM: (s, data) => {
    const initial = state();
    s.form = merge(initial.form, data);
  },
  SET_TYPE: (state, type) => {
    state.type = type;
  },
  RESET_FORM: (s) => {
    const initial = state();
    s.form = initial.form;
  },
  SET_DISPLAY_TYPE: (state, type) => {
    state.displayType = type;
    state.displayTypeEdited = true;
  },
};
