<template lang="pug">
div
  guide
  side-bar
  .content-wrapper(:class="{ 'content-wrapper-collapsed': navbarCollapsed }")
    nav-bar.no-grow
    .d-flex.justify-content-center.align-items-center.flex-grow-1(
      v-if="loading"
    )
      b-spinner(variant="primary", style="width: 7rem; height: 7rem")
    #scrollable(v-else)
      .page-wrapper
        nuxt
    //-   ------- MODALS ---------
    clientNotifications
    confirm-store-action

    //- New forms
    new-hyresvard-form
    new-anvandare-form
    new-transaction-form

    //- Edit forms
    edit-hyresvard-form
    edit-anvandare-form

    //- Combined forms
    hyresobjekt-form
    fastighet-form

    //- 
    GlobalSearchModal
</template>
<script>
import SideBar from "~/components/SideBar.vue";
import NavBar from "~/components/NavBar.vue";
import ClientNotifications from "~/components/ClientNotifications.vue";
import GlobalSearchModal from "../components/GlobalSearchModal.vue";
import ConfirmStoreAction from "~/components/utility/ConfirmStoreAction.vue";

// New Forms
import NewHyresvardForm from "~/components/forms/NewHyresvard.vue";
import NewAnvandareForm from "~/components/forms/NewAnvandare.vue";
import NewTransactionForm from "~/components/forms/NewTransaction.vue";

// Edit Forms
import EditHyresvardForm from "~/components/forms/EditHyresvard.vue";
import EditAnvandareForm from "~/components/forms/EditAnvandare.vue";

// Combined Forms
import HyresobjektForm from "~/components/forms/Hyresobjekt.vue";
import FastighetForm from "~/components/forms/Fastighet.vue";

// Guide
import Guide from "~/components/Guide";

export default {
  components: {
    SideBar,
    NavBar,
    ClientNotifications,
    ConfirmStoreAction,

    NewHyresvardForm,
    NewAnvandareForm,
    NewTransactionForm,

    EditHyresvardForm,
    EditAnvandareForm,

    HyresobjektForm,
    FastighetForm,

    Guide,

    GlobalSearchModal,
  },

  middleware: [
    ({ $auth, redirect }) => {
      if (
        $auth.user &&
        $auth.user.accountType !== "hyresvard" &&
        $auth.user.accountType !== "admin"
      ) {
        return redirect($auth.options.redirect.login);
      }
    },
    "setGtagContext",
  ],
  computed: {
    navbarCollapsed() {
      return this.$store.state.sidebar.collapsedLg;
    },
    loading() {
      return this.$store.state.loading;
    },
  },
};
</script>
<style lang="scss">
@import "./assets/scss/custom-lux-variables.scss"; //Custom bootstrap styling

body {
  background-color: $gray-300;
}
</style>
