<template lang="pug">
div
  confirm-action(v-model="show", @proceed="proceed()", @cancel="cancel()")
</template>
<script>
import ConfirmAction from "~/components/utility/ConfirmAction.vue";
export default {
  components: {
    ConfirmAction,
  },
  computed: {
    show: {
      get() {
        return this.$store.state.confirm.modal;
      },
      set(data) {
        this.$store.commit("confirm/SET_MODAL", data);
      },
    },
  },
  methods: {
    proceed() {
      this.$store.dispatch("confirm/proceed");
    },
    cancel() {
      this.$store.dispatch("confirm/cancel");
    },
  },
};
</script>
