import { gmapApi } from "vue2-google-maps";

/**
 * Geolocate using google maps api
 *
 * @param {*} address
 * @returns {Object} Location object with properties with lat and lng
 */
export default {
  data() {
    return {
      geocoder: null,
    };
  },
  computed: {
    google: gmapApi,
  },
  created() {
    this.$gmapApiPromiseLazy({}) // Otherwise you get "window is not defined" on site render
      .then(() => {
        this.geocoder = new this.google.maps.Geocoder();
      });
  },
  methods: {
    geocode(address, postalCode) {
      let searchObj = { address, region: "se" };
      if (postalCode) {
        searchObj.componentRestrictions = {
          postalCode,
        };
      }

      return new Promise((resolve, reject) => {
        if (!this.geocoder) reject("NO_GEOCODER");
        if (!address) reject("NO_ADDRESS");
        this.geocoder.geocode(searchObj, (results, status) => {
          if (status === "OK") {
            let res = results[0];

            let obj = {
              lng: res.geometry.location.lng(),
              lat: res.geometry.location.lat(),
              formattedAddress: res.formatted_address,
            };
            resolve(obj);
          } else {
            reject(status);
          }
        });
      });
    },
  },
};
