import merge from "deepmerge";

export const state = () => ({
  type: "new",
  form: {
    title: "",
    description: "",
    defaultContractor: null,
  },
});

export const actions = {
  async new({ dispatch, state }) {
    const res = await this.$axios.post(`hyresvard/complaints/categories`, {
      ...state.form,
    });
    dispatch(
      "clientNotifications/create",
      { title: "Ny kategori skapades.", type: "success" },
      { root: true }
    );
    return res.data;
  },
  setEdit({ commit }, category) {
    commit("SET_FORM", category);
    commit("SET_TYPE", "edit");
  },
  async edit({ dispatch, state }) {
    const res = await this.$axios.patch(
      `hyresvard/complaints/categories/${state.form._id}`,
      {
        ...state.form,
      }
    );
    dispatch(
      "clientNotifications/create",
      { title: "Kategorin uppdaterades.", type: "success" },
      { root: true }
    );
    return res.data;
  },
  async delete({ dispatch }, id) {
    const res = await this.$axios.delete(
      `hyresvard/complaints/categories/${id}`
    );
    dispatch(
      "clientNotifications/create",
      { title: "Kategorin raderades.", type: "warning" },
      { root: true }
    );
    return res.data;
  },
};
export const mutations = {
  SET_FORM: (s, data) => {
    const initial = state();
    s.form = merge(initial.form, data);
  },
  SET_TYPE: (state, type) => {
    state.type = type;
  },
  RESET_FORM: (s) => {
    const initial = state();
    s.form = initial.form;
  },
};
