<template lang="pug">
b-modal#global-search-modal(
  size="lg",
  static,
  lazy,
  ref="globalSearchModal",
  hide-footer,
  hide-header,
  @show="searchGlobally",
  @shown="onShown"
)
  h5.text-center Sök TenFAST
  el-input#global-search-input.w-100(v-model="searchValue", @input="debouncer")
    template(#suffix): fa.el-input__icon(icon="magnifying-glass")
  .my-2(v-if="queriedData") 
    | Sökte "{{ queriedData.lastSearch }}"
    b-badge.ml-2(variant="primary", pill) 
      | {{ queriedData.totalCount }} resultat
      span.ml-2(v-if="isLoading"): b-spinner(style="width: 14px; height: 14px")
    hr.my-2
    .text-center
      b-checkbox-group(
        v-model="modelTypes.enabled",
        :options="modelTypes.options",
        @change="checkboxChange"
      )
    hr.my-2
  .text-center.p-4(v-else): b-spinner
  ul.pl-2
    b-media.media-hover.rounded.p-1.clickable.mb-3(
      tag="li",
      v-for="item in searchRecords",
      :key="item._id",
      @click="() => { $router.push(item.link); hide(); }"
    )
      template(#aside)
        nuxt-link(
          :to="item.link ? item.link : undefined",
          @click.native="hide"
        )
          fa(:icon="item.icon", size="lg")

      nuxt-link(:to="item.link ? item.link : undefined", @click.native="hide")
        h5.mt-0.mb-1(style="text-transform: none") {{ item.title }}
          small.ml-2: b-badge(
            v-for="(status, i) in item.statuses",
            v-if="status",
            :key="i",
            :variant="status.variant",
            :class="{ 'ml-2': i !== 0 }"
          ) {{ status.text }}
      table(v-if="item.dataMap?.length")
        tr(v-for="[listKey, listItem] in item.dataMap")
          //-td: b {{ listKey }}:&nbsp;
          td {{ listItem }}
  ApiPagination(
    :queriedData="queriedData",
    :queriedCursor.sync="queriedCursor",
    @change="searchGlobally"
  )
</template>
<script>
import { debounce } from "lodash";
import FilterBar from "./tables/bootstrap-table/FilterBar.vue";
import ApiPagination from "./tables/bootstrap-table/ApiPagination.vue";
import { buildFilterQuery } from "./tables/bootstrap-table/types";
export default {
  components: { FilterBar, ApiPagination },
  data() {
    return {
      modelTypes: {
        options: [
          "Avtal",
          "Hyresobjekt",
          { text: "Hyresgäst", value: "Hyresgast" },
          "Fastighet",
          { text: "Felanmälan", value: "Complaint" },
        ],
        enabled: [],
      },
      searchValue: "",
      queriedData: null,
      searchData: [],
      queriedCursor: {
        id: null,
        next: false,
        sortValue: null,
      },
      isLoading: false,
      requestAbort: this.$axios.CancelToken.source(),
      debouncer: debounce(this.searchGlobally, 300),
      appliedFilters: [],
      filterFields: [
        {
          key: "type",
          label: "Typ",
          sortable: true,
          filter: {
            type: "number",
            placeholder: "Sök avtalsnummer",
            actualValue: "reference",
          },
        },
      ],
      modelTypeToMap: {
        Avtal: {
          icon: "file-contract",
          onClick: (record) => {
            return { name: "hyresvard-avtal-id", params: { id: record._id } };
          },
        },
        Fastighet: {
          icon: "city",
          onClick: (record) => {
            return {
              name: "hyresvard-fastigheter-id",
              params: { id: record._id },
            };
          },
        },
        Hyresobjekt: {
          icon: "building",
          onClick: (record) => {
            return {
              name: "hyresvard-hyresobjekt-id",
              params: { id: record._id },
            };
          },
        },
        Hyresgast: {
          icon: "circle-user",
          onClick: (record) => {
            return {
              name: "hyresvard-hyresgaster-id",
              params: { id: record._id },
            };
          },
        },
        Complaint: {
          icon: "building",
          onClick: (record) => {
            return {
              name: "hyresvard-felanmalan-id",
              params: { id: record._id },
            };
          },
        },
      },
    };
  },
  computed: {
    searchRecords() {
      const data = this.searchData.map((record) => {
        let title = "",
          statuses = [];
        let dataMap = {};

        const addrFn = (input) => {
          return this.$filters.toCombinedAddress([
            input.postadress,
            input.postnummer,
            input.stad,
          ]);
        };
        switch (record.modelType) {
          case "Avtal": {
            title = "Avtal nr. " + record.reference;
            dataMap = {
              Hyresgaster:
                record.hyresgaster
                  .map((hg) => {
                    return this.$filters.hgDisplayName(hg) || "Saknar namn";
                  })
                  .join(", ") || "Inga",
              Hyresobjekt:
                record.hyresobjekt
                  .map((ho) => {
                    return this.$filters.hoDisplayName(ho) ?? "Saknar namn";
                  })
                  .join(", ") || "Inga",
              Hyra:
                this.$filters.currency(
                  this.$filters.sumHyror(
                    record.hyror,
                    "exclude",
                    record["_dVersion"] === "v1"
                  )
                ) +
                "/" +
                this.$filters.toReadablePeriod(record.aviseringsFrekvens),
            };

            if (record.cancellation.cancelled) {
              statuses.push({
                text: "Avslutat",
                variant: "danger",
              });
            } else if (record.signed) {
              statuses.push({
                text: "Signerat",
                variant: "success",
              });
            }

            break;
          }
          case "Hyresobjekt": {
            title = this.$filters.hoDisplayName(record) ?? "Saknar";

            dataMap = {
              Typ: this.$filters.hoFullType(record),
              Kvm: record.kvm + " kvm",
              Hyra: this.$filters.currency(record.hyra),
              Adress: addrFn(record),
            };
            break;
          }
          case "Hyresgast": {
            title = this.$filters.hgDisplayName(record);
            dataMap = {
              [record.isCompany ? "Orgnr." : "Pesronnr"]: record.idbeteckning,
              Adress: addrFn(record),
              Telefonr: record.phone,
            };

            if (record.isCompany) {
              statuses.push({
                text: "Företag",
                variant: "primary",
              });
            }
            break;
          }
          case "Fastighet": {
            title = this.$filters.hoDisplayName(record) ?? "Saknar";
            dataMap = {
              Beteckning: record.fastighetsbeteckning,
              Adress: addrFn(record),
            };
            break;
          }
          case "Complaint": {
            title = `[Nr.${record.reference}] ${record.subject.substring(
              0,
              250
            )}`;
            dataMap = {
              Hyresgast: this.$filters.hgDisplayName(record.hyresgast),
              Hyresobjekt:
                record.hyresobjekt
                  .map((ho) => {
                    return this.$filters.hoDisplayName(ho) ?? "Saknar namn";
                  })
                  .join(", ") || "Inga",
              Beskrivning:
                record.description?.substring(0, 250) +
                (record.description?.length > 250 ? "..." : ""),
            };

            statuses.push(
              {
                text: this.$tt("complaintPriority." + record.priority, ""),
                variant: "info",
              },
              {
                variant:
                  this.$store.state.complaints.stateVariantDictionary[
                    record.state
                  ],
                text: this.$tt("complaintStates." + record.state),
              }
            );
            break;
          }
        }

        const mapping = this.modelTypeToMap[record.modelType];
        return {
          title,
          _id: record._id,
          icon: mapping?.icon,
          dataMap: Object.entries(dataMap).slice(0, 5),
          link: mapping?.onClick(record),
          statuses,
        };
      });

      return data;
    },
  },
  mounted() {
    // Bind keypress
    window.addEventListener("keydown", this.shortcutHandler);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.shortcutHandler);
  },
  methods: {
    async searchGlobally() {
      this.requestAbort.cancel();
      this.requestAbort = this.$axios.CancelToken.source();
      this.isLoading = true;

      const queryParam = buildFilterQuery(
        this.filterFields,
        this.appliedFilters
      );

      let id = this.queriedCursor.id;
      if (this.queriedData?.lastSearch !== this.searchValue) {
        id = null;
      }

      if (this.queriedCursor.id) {
        queryParam["paginate"] = btoa(
          JSON.stringify({
            id,
            sortValue: this.queriedCursor.sortValue,
            isNext: this.queriedCursor.next,
          })
        );
      }

      const lastSearch = this.searchValue;
      queryParam["filter[globalFilter]"] = this.searchValue;
      queryParam["limit"] = 5;
      if (this.modelTypes.enabled.length) {
        queryParam["types"] = this.modelTypes.enabled
          .map((v) => v.toLowerCase())
          .join(",");
      }

      try {
        this.queriedData = (
          await this.$axios.get("/hyresvard/search", {
            params: Object.assign({}, queryParam),
            cancelToken: this.requestAbort.token,
            validateStatus: null,
          })
        ).data;

        if (Array.isArray(this.queriedData?.records)) {
          this.searchData = this.queriedData.records;
          this.queriedData.lastSearch = lastSearch;
        }

        this.isLoading = false;
      } catch (err) {
        if (!this.$axios.isCancel(err)) throw err;
      }
    },
    shortcutHandler(e) {
      if (e.ctrlKey && e.shiftKey && e.key === "F") {
        this.$refs.globalSearchModal.toggle();
      }
    },
    checkboxChange() {
      this.queriedCursor.id = null;
      this.searchGlobally();
    },
    hide() {
      this.$refs.globalSearchModal.hide();
    },
    onShown() {
      const el = document.getElementById("global-search-input");
      if (el) {
        el.focus({ focusVisible: true });
      }
    },
  },
};
</script>
<style lang="scss">
.media-hover:hover {
  background-color: #ececec;
}
</style>
